import { DicomWebHelper, IAccessOptions } from 'common';
import dayjs from 'dayjs';

export const PacsExplorerInitialState = {
    selectedPacs: '',
    migrationModalOpen: false,
};

export interface IPacsExplorerState {
    selectedPacs: string;
    migrationModalOpen: boolean;
}

export interface IPacs {
    _id?: string;
    name: string;
    description: string;
    url: string;
    access_options: IAccessOptions;
    proxy?: boolean;
    default?: boolean;

    created_timestamp?: number;
    last_updated_timestamp?: number;
}

export type PacsView = 'explorer' | 'management' | 'migration';

export interface BasePacsRequestPayload {
    token?: string;
    baseUrl?: string;
    includefield?: Array<string>;
}

export interface StudyRequestPayload extends BasePacsRequestPayload {
    offset?: number;
    limit?: number;
    '0020000D'?: string[];
}

export interface SeriesRequestPayload extends BasePacsRequestPayload {
    StudyInstanceUID: string;
}

export function transformToPacsStudy(data: any): PacsStudy {
    const humanizedData = DicomWebHelper.humanizeDicomData(data);
    return {
        ...humanizedData,
        date: DicomWebHelper.getString(data[DicomTagMap.StudyDate]),
        time: DicomWebHelper.getString(data[DicomTagMap.StudyTime]),
        accession: DicomWebHelper.getString(data[DicomTagMap.AccessionNumber]),
        mrn: DicomWebHelper.getString(data[DicomTagMap.PatientId]),
        patientName: DicomWebHelper.getName(data[DicomTagMap.PatientName]),
        instances: DicomWebHelper.getString(data['00201208']),
        description: DicomWebHelper.getString(data[DicomTagMap.StudyDescription]),
        modalities: DicomWebHelper.getModalities(data['00080060'], data['00080061']),
    };
}

export interface ThumbnailRequestPayload extends Omit<BasePacsRequestPayload, 'includefield'> {
    StudyInstanceUID: string;
    SeriesInstanceUID: string;
    SOPInstanceUID: string;
}

export interface DicomTag {
    Value: any;
    vr: string;
}

export const DicomTagMap = {
    StudyDescription: '00081030',
    StudyInstanceUID: '0020000D',
    PatientId: '00100020',
    PatientName: '00100010',
    StudyDate: '00080020',
    StudyTime: '00080030',
    RequestedProcedureDescription: '00321060',
    AccessionNumber: '00080050',
    NumberOfStudyRelatedInstances: '00201208',
    DeviceDescription: '00500020',
    LongDeviceDescription: '00500021',
    Manufacturer: '00080070',
    ManufacturerModelName: '00081090',
    BodyPartExamined: '00180015',
};

export interface PacsStudy extends Record<keyof typeof DicomTagMap, string> {
    date: string;
    time: string;
    accession: string;
    mrn: string;
    patientName: string;
    instances: number;
    description: string;
    modalities: string[];
    procudureDescription?: string;
    StudyInstanceUID: string;
}

export const DicomFilterTagMap = {
    modality: '00080061',
    patientName: DicomTagMap.PatientName,
    description: DicomTagMap.StudyDescription,
    accession: DicomTagMap.AccessionNumber,
};

export function parseDicomDate(date: string) {
    if (!date || typeof date !== 'string') return;
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(4, 6));
    const day = Number(date.substring(6, 8));

    return dayjs().year(year).month(month).day(day);
}

export function parseDicomTime(time: string) {
    if (!time || typeof time !== 'string') return;

    const hour = Number(time.substring(0, 2));
    const minute = Number(time.substring(2, 4));
    const second = Number(time.substring(4, 6));

    return dayjs().hour(hour).minute(minute).second(second);
}

export function parseDicomDateTime(date: string, time: string) {
    const _date = parseDicomDate(date);
    const _time = parseDicomTime(time);

    _date.hour(_time.get('hour')).minute(_time.get('minute')).second(_time.get('second'));
    return _date;
}

export * from './Migration';

