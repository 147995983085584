import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { IWindowLevel, ProblemTypeKeys } from 'common';
export interface IAnnotationEl {
    image_id?: string;
    study_id?: string;
    user_id?: string;
    config_id?: string;
    annotation: Array<IAnnotation>;
}

export interface IAssistedSliceSelection {
    instance_id: string;
    image_id: string;
    measurement_info?: IMeasurementInfo;
    box?: Array<IPoint>;
}

export interface IAnnotation {
    id?: string;
    label?: number | string | CheckboxValueType[];
    name?: string;
    image_id: string; // useful in study datasets
    config_id?: string;
    instance_id?: string; // useful in study datasets
    series_id?: string; // useful in study datasets
    type?: AnnotationShapeType | 'classification';
    points?: Array<IPoint>;
    value?: any;
    unit?: MeasurementUnit;
    measurement_info?: IMeasurementInfo;
    options?: Dictionary;
    prediction_id?: string;
    mask?: any;
    shape?: any;
    related_instance_list?: Array<string>;
    window_level?: IWindowLevel;
    imageUrl?: string;
}

export interface IMeasurementInfo {
    form?: string;
    objectName?: string;
    measurement?: string;
}

export type AnnotationShapeType = 'length' | 'polygon' | 'angle' | 'cobbAngle' | 'mask' | 'bidirectional' | 'rect' | 'point';

export type AnnotationTool =
    | 'Length'
    | 'CobbAngle'
    | 'Brush'
    | 'Angle'
    | 'FreehandRoi'
    | 'Bidirectional'
    | 'RectangleRoi'
    | 'BrushEraser'
    | 'FreehandScissors'
    | 'CorrectionScissors'
    | 'AssistedSliceSelectionCross'
    | 'SphericalGrowCutSegmentation'
    | 'SAMPointPrediction'
    | 'SAMRectPrediction';

export const BrushToolList = [
    'Brush',
    'BrushEraser',
    'CorrectionScissors',
    'FreehandScissors',
    'SphericalGrowCutSegmentation',
    'SAMPointPrediction',
    'SAMRectPrediction',
];

export type MeasurementUnit =
    | 'mm'
    | 'cm'
    | 'm'
    | 'in'
    | 'ft'
    | 'yd'
    | 'px'
    | 'mm2'
    | 'cm2'
    | 'm2'
    | 'in2'
    | 'ft2'
    | 'yd2'
    | 'px2'
    | 'mm3'
    | 'cm3'
    | 'm3'
    | 'in3'
    | 'ft3'
    | 'yd3'
    | 'px3'
    | 'degree'
    | 'radian'
    | 'custom';

export interface IPoint {
    x: number;
    y: number;
}

export const annotationObjectTypeMap: Partial<Record<ProblemTypeKeys, AnnotationShapeType>> = {
    segmentation: 'polygon',
    instance_segmentation: 'polygon',
    object_detection: 'rect',
};

export const responseFieldMap: Partial<Record<ProblemTypeKeys, 'masks' | 'objects'>> = {
    segmentation: 'masks',
    instance_segmentation: 'masks',
    object_detection: 'objects',
};

export const responseObjectFieldMap: Partial<Record<ProblemTypeKeys, 'box' | 'mask'>> = {
    segmentation: 'mask',
    instance_segmentation: 'mask',
    object_detection: 'box',
};

export const AnnotationToolShapeMap: Partial<Record<AnnotationTool, AnnotationShapeType>> = {
    Brush: 'mask',
    BrushEraser: 'mask',
    CorrectionScissors: 'mask',
    FreehandScissors: 'mask',
    SphericalGrowCutSegmentation: 'mask',
    SAMPointPrediction: 'mask',
    SAMRectPrediction: 'mask',
    FreehandRoi: 'polygon',
    Length: 'length',
    Angle: 'angle',
    CobbAngle: 'cobbAngle',
    Bidirectional: 'bidirectional',
    RectangleRoi: 'rect',
};

export const DefaultToolMap: Partial<Record<AnnotationShapeType, AnnotationTool>> = {
    mask: 'Brush',
    polygon: 'FreehandRoi',
    length: 'Length',
    angle: 'Angle',
    cobbAngle: 'CobbAngle',
    bidirectional: 'Bidirectional',
    rect: 'RectangleRoi',
};

export const AnnotationTypeMenuItems = [
    {
        title: 'Length',
        value: 'length',
    },
    {
        title: 'Polygon',
        value: 'polygon',
    },
    {
        title: 'Angle',
        value: 'angle',
    },
    {
        title: 'Cobb Angle',
        value: 'cobbAngle',
    },
    {
        title: 'Mask',
        value: 'mask',
    },
    {
        title: 'Bidirectional',
        value: 'bidirectional',
    },
    {
        title: 'Rectangle',
        value: 'rect',
    },
    {
        title: 'Point',
        value: 'point',
    },
];

export const ProblemTypeShapeMap: Partial<Record<ProblemTypeKeys, AnnotationShapeType[]>> = {
    segmentation: ['polygon', 'mask'],
    instance_segmentation: ['polygon', 'mask'],
    semantic_segmentation: ['polygon', 'mask'],
    object_detection: ['rect'],
};

export enum AnnotationStatus {
    completed = 'completed',
    inprogress = 'inprogress',
    pending = 'pending',
    approved = 'approved',
    rejected = 'rejected',
    partiallyCompleted = 'partiallyCompleted',
}

export const AnnotationToolProblemTypeMap: Record<AnnotationShapeType, ProblemTypeKeys> = {
    angle: 'segmentation',
    cobbAngle: 'segmentation',
    length: 'segmentation',
    mask: 'segmentation',
    polygon: 'segmentation',
    rect: 'object_detection',
    bidirectional: 'segmentation',
    point: 'segmentation',
};

export interface IPreAnnotation {
    image_id: string;
    study_id: string;
    user_list: string[];
    annotation_id?: string;
    label?: number;
    config_id?: string;
    name: string;
    type?: AnnotationShapeType;
    points?: Array<IPoint>;
    mask?: { mask: string };
    value?: number;
    measurement_info?: IMeasurementInfo;
    prediction_id?: string;
    model_id?: string;
}
