import cx from 'classnames';
import { RoutingHelper, getMessages } from 'common';
import { GButton, GDataGrid, GModal } from 'components';
import { IGModalProps } from 'components/GModal';
import { ErrorCloseX, Stop } from 'components/assets/icons';
import { selectUser } from 'pages/Authentication/store/Selectors';
import { stopModelTransaction } from 'pages/Models/store/actions';
import { selectModelListLoading } from 'pages/Models/store/selectors';
import { setPlatformAlert, setPlatformAlertModalVisible } from 'pages/Platform/store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { findModel, selectDeploymentList, selectModelList } from 'redux/selectors';
import { selectPlatformAlert, selectPlatformAlertModalVisible } from '../../store/selectors';
import Styles from './Styles.module.scss';

export default function PlatformAlertModal() {
    const isModalVisible = useSelector(selectPlatformAlertModalVisible);

    const dispatch = useDispatch();

    const user = useSelector(selectUser);
    const deploymentList = useSelector(selectDeploymentList);
    const modelListLoading = useSelector(selectModelListLoading);
    const modelList = useSelector(selectModelList);
    const alert = useSelector(selectPlatformAlert);
    const [selectedModels, setSelectedModels] = useState<any>([]);

    if (!alert) return null;

    const deploymentListOfUser = deploymentList?.filter(item => item?.userId === user?.userId);

    const handleCloseModal = () => dispatch(setPlatformAlertModalVisible(false));
    const tableData = deploymentListOfUser
        ?.filter(item => alert?.deployment_id.includes(item?.deploymentId))
        ?.map(item => ({
            key: item.deploymentId,
            modelName: findModel(modelList, item?.modelId)?.modelName,
            modelId: item.modelId,
            deploymentId: item.deploymentId,
        }));
    const isDeploymentListOfUserEmpty = tableData?.length < 1;



    const columns = [
        {
            dataIndex: 'modelName',
            title: getMessages('000018'),
            render: (value: string, record: any, index: number) => (
                <div
                    className="pBody-normal"
                    key={index}
                    onClick={handleCloseModal}
                >
                    <Link to={RoutingHelper.Model({ activeView: 'model-details', modelId: record?.modelId }).uri}>
                        {record?.modelName}
                    </Link>
                </div>
            ),
        },
        {
            dataIndex: 'modelId',
            title: getMessages('000030'),
        },
    ];

    function stopModels() {
        for (let i = 0; i < selectedModels?.length; i++) {
            dispatch(stopModelTransaction({ deploymentId: selectedModels[i]?.deploymentId, modelId: selectedModels[i]?.modelId }));
        }
        handleCloseModal();
        dispatch(setPlatformAlert(null));
    }

    const modalProps: IGModalProps = {
        open: isModalVisible ?? false,
        closable: false,
        width: '560px',
        className: Styles.PlatformAlertModal,
        maskStyle: { backdropFilter: 'blur(20px)' },
        footer: null,
    };
    return (
        <GModal {...modalProps}>
            <div className={Styles.ModalHeader}>
                <ErrorCloseX />
                <div className="h5-bold red">Warning</div>
            </div>
            <div>
                <div className="subtitle2-medium white text-center mtb-8">{alert?.text}</div>
            </div>
            {!isDeploymentListOfUserEmpty ? (
                <GDataGrid
                    loading={modelListLoading}
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    className={Styles.ModelDeploymentTable}
                    id="platform-model-deployment-table"
                    rowSelection={{
                        onChange: (selectedRows: any) => {
                            setSelectedModels(selectedRows);
                        },
                    }}
                />
            ) : null}
            <div className={cx(isDeploymentListOfUserEmpty ? 'flex-center' : 'flex-end', 'gap8')}>
                <GButton
                    type="default"
                    onClick={handleCloseModal}
                    className={Styles.CloseButton}
                >
                    Close
                </GButton>
                {!isDeploymentListOfUserEmpty ? (
                    <GButton
                        disabled={selectedModels?.length === 0}
                        onClick={stopModels}
                        type="ghost"
                        endIcon={<Stop />}
                        id="model-detail-model-stop"
                    >
                        {getMessages('000386')}
                    </GButton>
                ) : null}
            </div>
        </GModal>
    );
}
