import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

export const selectCinePlay = (state: RootState) => state.dicomViewer.cinePlay;
export const selectFrameRate = (state: RootState) => state.dicomViewer.frameRate;
export const selectGridView = (state: RootState) => state.dicomViewer.gridView;
export const selectCineMode = (state: RootState) => state.dicomViewer.cineMode;
export const selectActiveViewport = (state: RootState) => state.dicomViewer.activeViewport;
export const selectWindowLevel = (state: RootState) => state.dicomViewer.windowLevel;
export const selectSelectedLabel = (state: RootState) => state.dicomViewer.selectedLabel;
export const selectPrefetch = (state: RootState) => state.dicomViewer.prefetch;
export const selectSmartSegmentationEnabled = (state: RootState) => state.dicomViewer.smartSegmentationEnabled;
export const selectBrushRadius = (state: RootState) => state.dicomViewer.brushRadius;
export const selectSegmentOpacity = (state: RootState) => state.dicomViewer.segmentOpacity;
export const selectBrushConfig = (state: RootState) => state.dicomViewer.brushConfig;
export const selectEraserConfig = (state: RootState) => state.dicomViewer.eraserConfig;
export const selectActiveAnnotationTool = (state: RootState) => state.dicomViewer.activeAnnotationTool;
export const selectIsViewportFocused = (state: RootState) => state.dicomViewer.isViewportFocused;
export const selectLabelMapVisibility = (state: RootState) => state.dicomViewer.labelMapVisibility;
export const selectLabelColorMap = (state: RootState) => state.dicomViewer.labelColorMap;

export const selectViewportStateMap = (state: RootState) => state.dicomViewer.viewportStateMap;

export const selectActiveViewportState = createSelector(
    selectActiveViewport,
    selectViewportStateMap,
    (activeViewport, viewportStateMap) => viewportStateMap?.[activeViewport]
);

export function getViewportStateSelector(index: number) {
    return createSelector(selectViewportStateMap, viewportStateMap => viewportStateMap?.[index]);
}

export function getViewportSeriesIdSelector(index: number) {
    return createSelector(getViewportStateSelector(index), viewportState => viewportState?.seriesId);
}
