import { _uuid } from 'common/Utils';
import { AssignmentRole } from 'pages/AnnotationCS/entities/IAnnotationTracking';
import { IToolState } from './interfaces';

export function ToolStateFactory(data?: Partial<IToolState>): IToolState {
    return {
        isSaveStudyModalOpen: data?.isSaveStudyModalOpen ?? false,
        isStatusInfoModalOpen: data?.isStatusInfoModalOpen ?? false,
        studySessionId: data?.studySessionId || _uuid(),
        isStudyTableModalOpen: data?.isStudyTableModalOpen ?? false,
        currentRole: data?.currentRole || AssignmentRole.Annotator,
        currentReader: data?.currentReader || '',
        isAnnotationChanged: data?.isAnnotationChanged || false,
        activeShapeType: data?.activeShapeType || null,
        preAnnotationActivated: data?.preAnnotationActivated || false,
    };
}
