import { _isEmpty } from 'common/Utils';
import { Dictionary } from 'common/entities';
import { NavigateOptions } from 'react-router-dom';
import { EventBus } from '../EventBus';
import {
    IAnnotationUriArgs,
    IAuthUriArgs,
    IBaseUriArgs,
    IDatasetUriArgs,
    IMainUriArgs,
    IModelUriArgs,
    IPlatformUriArgs,
    IProjectUriArgs,
    IValidationUriArgs,
    IViewerUriArgs,
    UserPageUriArgs,
} from './entities';

export class RoutingHelper {
    public static Validation({ params, validationId = '', activeView, subView }: IValidationUriArgs) {
        let path = `/validation/${activeView ?? ''}`;
        if (activeView === 'analysis') {
            path += `/${validationId}/${subView ?? ''}`;
        }
        if (activeView === 'external-validation') {
            path += `/${Boolean(validationId) ? `${validationId}/` : ''}${subView ?? ''}`;
        }

        return this.returnPayload(path, params);
    }

    public static Main({ params, activeView }: IMainUriArgs) {
        let path = `/main/${activeView ?? ''}`;

        return this.returnPayload(path, params);
    }

    public static Project({ activeView, params, projectId, detailsActiveView = 'overview' }: IProjectUriArgs) {
        let path = `/project/${activeView ?? ''}`;

        if (activeView === 'project-details') {
            path += `/${projectId}/${detailsActiveView}`;
        }

        return this.returnPayload(path, params);
    }

    public static Annotation({
        activeView,
        params,
        projectId,
        detailsActiveView = 'summary',
        agreementActiveKey = 'inter_rating',
    }: IAnnotationUriArgs) {
        let path = `/annotation/${activeView ?? ''}`;
        switch (activeView) {
            case 'annotation-details':
                if (detailsActiveView === 'annotation-agreement') {
                    path += `/${projectId}/${detailsActiveView}/${agreementActiveKey}`;
                    break;
                }
                path += `/${projectId}/${detailsActiveView}`;
                break;
            case 'annotation-tool':
            case 'annotation-viewer':
            case 'annotation-viewer-3D':
                path += `/${projectId}`;
                break;
        }

        return this.returnPayload(path, params);
    }
    public static Dataset({
        activeView,
        params,
        detailsActiveView,
        curationStep,
        analysisActiveView,
        datasetId = '',
        study,
    }: IDatasetUriArgs) {
        let path = `/data/${activeView ?? ''}`;

        switch (activeView) {
            case 'dataset-details':
                path += `/${datasetId}/${detailsActiveView || 'overview'}`;

                if (detailsActiveView === 'data' && study) {
                    path += `?study=${study}`;
                }
                break;
            case 'DatasetCuration':
                path += `/${curationStep ?? ''}`;
                break;
            case 'dataset-analysis':
                path += `/${datasetId}/${analysisActiveView ?? ''}`;
                break;
        }

        return this.returnPayload(path, params);
    }

    public static Model({ activeView, params, modelId, detailsActiveView }: IModelUriArgs) {
        let path = `/models/${activeView ?? ''}`;

        switch (activeView) {
            case 'model-details':
                path += `/${modelId ?? ''}/${detailsActiveView ?? ''}`;
                break;
        }

        return this.returnPayload(path, params);
    }

    public static Platform({ activeView, params }: IPlatformUriArgs) {
        let path = `/platform/${activeView ?? ''}`;

        return this.returnPayload(path, params);
    }

    public static User({ activeView, params, location }: UserPageUriArgs) {
        let path = `/user/${location ?? ''}/${activeView ?? ''}`;

        return this.returnPayload(path, params);
    }

    public static Playground({ params }: IBaseUriArgs) {
        return this.returnPayload('/playground', params);
    }

    public static Authentication({ activeView, params }: IAuthUriArgs) {
        return this.returnPayload(`/auth/${activeView ?? ''}`, params);
    }

    public static Viewer({ mode, params }: IViewerUriArgs) {
        return this.returnPayload(`/viewer/${mode ?? ''}`, params);
    }

    public static navigate(path: string, options?: NavigateOptions) {
        EventBus.emit('navigate', { path, options });
    }

    private static returnPayload(uri: string, params?: Dictionary) {
        uri = this.getUriWithParams(uri, params);
        return {
            uri,
            navigate: (options?: NavigateOptions) => this.navigate(uri, options),
        };
    }

    private static getUriWithParams(path: string, params: Dictionary = {}) {
        const _params = new URLSearchParams(params).toString();

        if (_isEmpty(_params)) return path;

        return path + '?' + _params;
    }
}

export * from './URLHelper';

