import { getDefaultHeaders } from 'common/services';
import AssistedSliceSelectionRectTool from '../CustomTools/AssistedSliceSelectionRectTool';
import { SmartBoundingBoxTool } from '../CustomTools/SmartBoundingBoxTool';
import SphericalGrowCutSegmentationTool from '../CustomTools/SmartGrowCircleTool';
import {
    Hammer,
    activeMeasurementColor,
    cornerstone,
    cornerstoneMath,
    cornerstoneNiftiImageLoader,
    cornerstoneTools,
    cornerstoneWADOImageLoader,
    cornerstoneWebImageLoader,
    defaultMeasurementColor,
    dicomParser,
} from './CornerstoneHelper';

export const CSLoadState = {
    isLoaded: false,
};

export default function initCornerstone() {
    if (CSLoadState.isLoaded) return;

    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    cornerstoneWebImageLoader.external.dicomParser = dicomParser;
    cornerstoneNiftiImageLoader.external.cornerstone = cornerstone;

    initCSConfigs();
    CSLoadState.isLoaded = true;
}

function initCSConfigs() {
    const toolsConfig = [
        {
            moduleName: 'globalConfiguration',
            configuration: {
                showSVGCursors: true,
                mouseEnabled: true,
                touchEnabled: true,
                autoResizeViewports: true,
                isVisibleAngleTextBox: false,
                isVisibleLengthTextBox: false,
                isVisibleRectRoiTextBox: false,
                isVisibleCobbAngleTextBox: false,
                globalToolSyncEnabled: true,
            },
        },
        {
            moduleName: 'segmentation',
            configuration: {
                outlineWidth: 2,
            },
        },
    ];

    cornerstoneTools.disableLogger();
    cornerstoneTools.init(toolsConfig);

    cornerstoneTools.addTool(SphericalGrowCutSegmentationTool);
    cornerstoneTools.addTool(AssistedSliceSelectionRectTool);
    cornerstoneTools.addTool(SmartBoundingBoxTool);

    const segmodule = cornerstoneTools.getModule('segmentation');
    segmodule.configuration.outlineWidth = 2;
    segmodule.configuration.radius = 10;
    segmodule.configuration.renderOutline = true;
    segmodule.configuration.arrayType = 1;
    segmodule.configuration.fillAlpha = 0.4;

    cornerstoneTools.toolColors.setToolColor(defaultMeasurementColor);
    cornerstoneTools.toolColors.setActiveColor(activeMeasurementColor);

    cornerstoneTools.loadHandlerManager.setErrorLoadingHandler((error: any) => {
        console.error(error);
        throw error;
    });

    cornerstoneWADOImageLoader.webWorkerManager.initialize(workerConfig);
    cornerstoneWADOImageLoader.configure?.(loaderConfig);
    cornerstoneWebImageLoader.configure?.(loaderConfig);
    cornerstoneNiftiImageLoader.nifti?.configure?.(loaderConfig);

    window.cornerstoneImport = cornerstone;
    window.cornerstoneTools = cornerstoneTools;
}

const workerConfig = {
    maxWebWorkers: Math.min(navigator.hardwareConcurrency, 2),
    startWebWorkersOnDemand: true,
    taskConfiguration: {
        decodeTask: {
            initializeCodecsOnStartup: false,
            loadCodecsOnStartup: false,
            usePDFJS: false,
            strict: false,
        },
    },
};

const loaderConfig = {
    beforeSend: function (xhr: any) {
        const headers = getDefaultHeaders() as Dictionary<string>;

        for (const key in headers) {
            xhr.setRequestHeader(key, headers[key]);
        }
    },
};
