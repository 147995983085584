import { APISlice } from 'app/store/APISlice';
import { base64ResponseHandler } from 'common/Utils/base64ResponseHandler';
import { IDatasetResponsePayload } from 'common/entities';
import { ServiceEnum } from 'common/enum';
import {
    DicomTag,
    IPacs,
    MigrationFileRequestPayload,
    PacsMigration,
    PacsMigrationFile,
    SeriesRequestPayload,
    StudyRequestPayload,
    ThumbnailRequestPayload,
} from '.';
import { MigrationTrackingServerEvent } from './MigrationTrackingEventManager';

export const PacsService = APISlice.injectEndpoints({
    endpoints: builder => ({
        getPacsList: builder.query<Array<IPacs>, void>({
            query: () => ({
                url: `${ServiceEnum.Api}/Datasets/pacs/`,
            }),
        }),
        getPacsById: builder.query<IPacs, string>({
            query: id => ({
                url: `${ServiceEnum.Api}/Datasets/pacs/${id}`,
            }),
        }),

        getStudiesFromPacs: builder.query<Array<Dictionary<DicomTag>>, StudyRequestPayload>({
            query: ({ baseUrl = ServiceEnum.PacsService, token, ...params }) => ({
                url: `${baseUrl}/studies`,
                params,
                headers: {
                    'x-pacs-token': token,
                },
            }),
        }),

        getSeriesFromPacs: builder.query<Array<Dictionary<DicomTag>>, SeriesRequestPayload>({
            query: ({ baseUrl = ServiceEnum.PacsService, StudyInstanceUID, token, ...params }) => ({
                url: `${baseUrl}/studies/${StudyInstanceUID}/series`,
                params,
                headers: {
                    'x-pacs-token': token,
                },
            }),
        }),

        getPacsAccess: builder.query<{ token: string; url: string; dicomWebUrl: string }, string>({
            query: id => ({
                url: `${ServiceEnum.Api}/Datasets/pacs/${id}/access`,
            }),
        }),

        createDatasetFromPacs: builder.mutation<
            IDatasetResponsePayload,
            { studyList: Array<string>; datasetForm: any; pacsId: string }
        >({
            query: ({ studyList, datasetForm, pacsId }) => ({
                url: `${ServiceEnum.Api}/Datasets/CreateDatasetFromPacs`,
                method: 'POST',
                body: { studyList, datasetForm, pacsId },
            }),
        }),

        getImageThumbnail: builder.query<string, ThumbnailRequestPayload>({
            query: ({ baseUrl, SOPInstanceUID, SeriesInstanceUID, StudyInstanceUID, token }) => ({
                url: `${baseUrl}/studies/${StudyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/frames/1/rendered`,
                params: { quality: 75 },
                headers: {
                    'x-pacs-token': token,
                },
                responseHandler: base64ResponseHandler,
            }),
        }),

        getMigrationList: builder.query<{ data: Array<PacsMigration>; total: number }, string>({
            query: pacsId => ({
                url: `${ServiceEnum.Api}/Datasets/pacs/migration`,
                params: { pacsId },
            }),
            async onCacheEntryAdded(pacsId, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                const abortController = new AbortController();
                await cacheDataLoaded;

                MigrationTrackingServerEvent.subscribeEvent({
                    pacsId,
                    cb: data => {
                        updateCachedData(draft => {
                            const updateDraft = (data: PacsMigration) => {
                                if (!data?._id) return;
                                const index = draft.data?.findIndex(item => item._id === data._id);
                                if (index !== -1) {
                                    Object.assign(draft.data[index], data);
                                } else {
                                    draft.data?.unshift(data);
                                }
                            };
                            if (Array.isArray(data)) {
                                data.forEach(item => updateDraft(item.payload));
                            } else {
                                updateDraft(data.payload);
                            }
                        });
                    },
                    abortController,
                });

                cacheEntryRemoved.then(() => {
                    abortController.abort();
                });
            },
        }),

        getMigrationFiles: builder.query<{ data: Array<PacsMigrationFile>; total: number }, MigrationFileRequestPayload>({
            query: ({ processId, pacsId }) => ({
                url: `${ServiceEnum.Api}/Datasets/pacs/migration/${processId}`,
                params: { pacsId },
            }),
        }),

        startMigration: builder.mutation<PacsMigration, { pacsId: string; migration: PacsMigration }>({
            query: ({ pacsId, migration }) => ({
                url: `${ServiceEnum.Api}/Datasets/pacs/migration`,
                method: 'POST',
                body: { pacsId, migration },
            }),
        }),
    }),
});

export const {
    useGetPacsListQuery,
    useGetPacsByIdQuery,
    useGetStudiesFromPacsQuery,
    useGetPacsAccessQuery,
    useGetSeriesFromPacsQuery,
    useCreateDatasetFromPacsMutation,
    useGetImageThumbnailQuery,
    useGetMigrationListQuery,
    useGetMigrationFilesQuery,
    useStartMigrationMutation,
} = PacsService;
