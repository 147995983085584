import GAngleTool from './GAngleTool';
import GBrushTool from './GBrushTool';
import GCobbAngleTool from './GCobbAngleTool';
import GLengthTool from './GLengthTool';
import GRectangleRoiTool from './GRectangleRoiTool';
import SphericalGrowCutSegmentationTool from './SmartGrowCircleTool';
import { SAMPointPredictionTool } from './SmartPredictionTools/SAMPointPredictionTool';
import { SAMRectPredictionTool } from './SmartPredictionTools/SAMRectPredictionTool';

export * from './CustomThresholdTool';

export const ToolMap = {
    Length: GLengthTool,
    Brush: GBrushTool,
    BrushEraser: GBrushTool,
    RectangleRoi: GRectangleRoiTool,
    CobbAngle: GCobbAngleTool,
    Angle: GAngleTool,
    SAMRectPrediction: SAMRectPredictionTool,
    SAMPointPrediction: SAMPointPredictionTool,
    SphericalGrowCutSegmentation: SphericalGrowCutSegmentationTool,
};
