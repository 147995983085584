import { PropPath } from 'common/entities';
import { _set } from 'common/Utils';
import produce from 'immer';
import { useCallback, useState } from 'react';

export function useCollection<T extends object = any>(key: keyof T, initialValue: Array<T> = []) {
    const [coll, setColl] = useState(initialValue);

    const upsertItem = useCallback(
        (id: string, item: T) => {
            setColl(_coll =>
                produce(_coll, (draft: Array<T>) => {
                    const index = draft.findIndex(item => item?.[key] === id);

                    if (index === -1) draft.push(item);
                    else draft[index] = { ...draft[index], ...item };
                })
            );
        },
        [key]
    );

    const upsertMany = useCallback(
        (itemList: Array<T>) => {
            setColl(_coll =>
                produce(_coll, (draft: Array<T>) => {
                    itemList.forEach(item => {
                        const index = draft.findIndex(d => item?.[key] === d?.[key]);

                        if (index === -1) draft.push(item);
                        else draft[index] = { ...draft[index], ...item };
                    });
                })
            );
        },
        [key]
    );

    const updateItemField = useCallback(
        (id: string, path: PropPath<keyof T>, value: any) => {
            setColl(_coll =>
                produce(_coll, (draft: Array<T>) => {
                    const index = draft.findIndex(item => item?.[key] === id);

                    if (index !== -1) _set(draft[index], path as any, value);
                })
            );
        },
        [key]
    );

    const removeItem = useCallback(
        (id: string) => {
            setColl(_coll =>
                produce(_coll, (draft: Array<T>) => {
                    const index = draft.findIndex(item => item?.[key] === id);

                    if (index !== -1) draft.splice(index, 1);
                })
            );
        },
        [key]
    );

    const reset = useCallback(() => {
        setColl(initialValue);
    }, [initialValue]);

    const set = useCallback((newValue: Array<T>) => {
        setColl(newValue);
    }, []);

    return [coll, { upsertItem, upsertMany, updateItemField, removeItem, reset, set }] as const;
}
