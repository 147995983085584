import { getDocsURL, getMessages } from 'common';
import {
    Annotation,
    Application,
    Deployment,
    Documentation,
    FolderChartLine,
    Home,
    ModelLine20,
    Playground,
    Validation,
} from 'components/assets/icons';
import { Link } from 'react-router-dom';

export const linkMenuList = [
    {
        key: 'main',
        label: getMessages('001809'),
        icon: <Home />,
    },
    {
        key: 'playground',
        label: getMessages('000208'),
        icon: <Playground />,
    },
    {
        key: 'project',
        label: getMessages('001236'),
        icon: <FolderChartLine />,
    },
    {
        key: 'validation',
        label: getMessages('000002'),
        icon: <Validation />,
    },
    {
        key: 'models',
        label: getMessages('000614'),
        icon: <ModelLine20 />,
    },
    {
        key: 'data',
        label: getMessages('000003'),
        icon: <Deployment />,
    },
    {
        key: 'annotation',
        label: getMessages('000813'),
        icon: <Annotation />,
    },
    {
        key: 'viewer/playground',
        label: getMessages('002435'),
        icon: <Annotation />,
    },
    {
        key: 'platform',
        label: getMessages('000004'),
        icon: <Application />,
    },
    {
        key: 'documentation',
        label: getMessages('000488'),
        icon: <Documentation />,
        link: getDocsURL(),
    },
].map(item => ({
    ...item,
    label: item?.link ? (
        <a
            href={item.link}
            target="_blank"
            rel="next"
        >
            {item.label}{' '}
        </a>
    ) : (
        <Link to={`/${item.key}`}>{item.label}</Link>
    ),
}));
