import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Dictionary } from 'common';
import { _shuffle } from '../../Utils/LangUtils/_shuffle';
import { Axis, IAxisArgs, IChartHelperArgs, ICreateChartArgs, ICreateSeriesArgs, IGenerateAxesArgs } from './entities/entities';
import { AxesType, SeriesType } from './entities/enums';

export default class XYChart<T = am5xy.ColumnSeries | am5xy.LineSeries> {
    root: am5.Root;
    chart: am5xy.XYChart;
    xAxis: Axis;
    yAxis: Axis;
    data: Array<Dictionary<any>> = [];
    series: Array<T> = [];
    defaultTheme: am5.Theme;
    legend: am5.Legend;

    createRoot(args: IChartHelperArgs) {
        this.root = am5.Root.new(args.chartID);

        this.defaultTheme = am5.Theme.new(this.root);

        this.defaultTheme.rule('Grid').setAll(XYChart.defaultGridSettings);
        this.defaultTheme.rule('AxisLabel').setAll(XYChart.defaultAxisLabelSettings);

        this.defaultTheme.rule('ColorSet').set(
            'colors',
            (args.customColor ?? _shuffle(this.colorPalette)).map(color => am5.color(color))
        );

        if (args?.lightTheme) {
            this.root.setThemes([am5themes_Animated.new(this.root), this.defaultTheme]);
        } else {
            this.root.setThemes([am5themes_Animated.new(this.root), am5themes_Dark.new(this.root), this.defaultTheme]);
        }
    }

    createChart({ settings = {}, legendEnabled, ...args }: ICreateChartArgs, CB?: (chart?: am5xy.XYChart) => void) {
        this.chart = this.root.container.children.push(
            am5xy.XYChart.new(this.root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                layout: this.root.verticalLayout,
                maskBullets: false,
                ...settings,
            })
        );

        if (legendEnabled) {
            const options = am5.Legend.new(this.root, {
                centerX: am5.p0,
                x: am5.p0,
                marginBottom: 12,
                fillField: '#000',
                fill: am5.color('#000'),
            });

            if (args.legendPosition === 'bottom') this.legend = this.chart.children.push(options);
            else this.legend = this.chart.children.unshift(options);

            args?.borderRadiusEnabled &&
                this.legend.markerRectangles.template.setAll({
                    cornerRadiusTL: 10,
                    cornerRadiusTR: 10,
                    cornerRadiusBL: 10,
                    cornerRadiusBR: 10,
                });
        }

        if (args.scrollbarXEnabled) {
            this.chart.set(
                'scrollbarX',
                am5.Scrollbar.new(this.root, {
                    orientation: 'horizontal',
                })
            );
            this.styleScrollbar(this.chart.get('scrollbarX'));
        }

        if (args.scrollbarYEnabled) {
            this.chart.set(
                'scrollbarY',
                am5.Scrollbar.new(this.root, {
                    orientation: 'vertical',
                })
            );
            this.styleScrollbar(this.chart.get('scrollbarY'));
        }

        CB?.(this.chart);

        return this;
    }

    createAxes({ xAxis, yAxis }: IGenerateAxesArgs, CB?: (xAxis: Axis, yAxis: Axis) => void) {
        this.xAxis = this.chart.xAxes.push(this.getAxis(xAxis, 'AxisRendererX'));
        this.yAxis = this.chart.yAxes.push(this.getAxis(yAxis, 'AxisRendererY'));

        CB?.(this.xAxis, this.yAxis);

        return this;
    }

    createSeries(
        { type, seriesSettings, enableTooltip, ...args }: XYChartCreateSeriesArgs,
        CB?: (series: am5xy.ColumnSeries | am5xy.LineSeries) => void
    ) {
        const options = {
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            ...seriesSettings,
        };

        const series = this.chart.series.push(this.getSeries(type, options));

        if (!series) return;

        this.series?.push(series as any);

        if (enableTooltip && type === SeriesType.ColumnSeries) {
            (series as am5xy.ColumnSeries).columns.template.setAll({
                tooltipText: '{name}, {categoryX}:{valueY}',
                width: am5.percent(90),
            });
        }

        series.data.setAll(this.data);

        series.appear();

        this.legend?.data?.push(series);

        CB?.(series);

        return this;
    }

    setData(data: Array<Dictionary<any>>) {
        this.data = data;

        if (this.series) {
            this.series.forEach((_series: any) => {
                _series.data.setAll(this.data);
            });

            this.legend?.data?.setAll(this.series);
        }

        this.xAxis.isType('CategoryAxis') && this.xAxis.data.setAll(this.data);
        this.yAxis.isType('CategoryAxis') && this.yAxis.data.setAll(this.data);
    }

    pushData(data: Dictionary<any>) {
        this.data.push(data);

        if (this.series) {
            this.series.forEach((_series: any) => {
                _series.data.push(data);
            });
        }

        this.xAxis.isType('CategoryAxis') && this.xAxis.data.push(data);
        this.yAxis.isType('CategoryAxis') && this.yAxis.data.push(data);
    }

    private getSeries(type: SeriesType, options: any) {
        switch (type) {
            case SeriesType.ColumnSeries:
                return am5xy.ColumnSeries.new(this.root, options);

            case SeriesType.LineSeries:
                return am5xy.LineSeries.new(this.root, options);

            default:
                break;
        }
    }

    private getAxis(
        { type, categoryField, rendererSetting = {}, ...args }: IAxisArgs,
        axisType: 'AxisRendererX' | 'AxisRendererY'
    ) {
        switch (type) {
            case AxesType.category:
                return am5xy.CategoryAxis.new(this.root, {
                    renderer: am5xy[axisType].new(this.root, rendererSetting),
                    ...args.categoryAxisOptions,
                });

            case AxesType.value:
                return am5xy.ValueAxis.new(this.root, {
                    renderer: am5xy[axisType].new(this.root, rendererSetting),
                    ...args.valueAxisOptions,
                });

            case AxesType.date:
                return am5xy.DateAxis.new(this.root, {
                    renderer: am5xy[axisType].new(this.root, rendererSetting),
                    ...args.dateAxisOptions,
                });

            case AxesType.gaplessDate:
                return am5xy.GaplessDateAxis.new(this.root, {
                    renderer: am5xy.AxisRendererX.new(this.root, rendererSetting),
                    ...args.dateAxisOptions,
                });
            default:
                break;
        }
    }

    static readonly defaultGridSettings: Partial<am5xy.IGridSettings> = {
        stroke: am5.color('#666C75'),
        strokeDasharray: 7.5,
        strokeOpacity: 1,
        strokeWidth: 1,
    };

    static readonly defaultAxisLabelSettings: Partial<am5xy.IAxisLabelSettings> = {
        fill: am5.color('#fff'),
        fontSize: 12,
        fontFamily: 'Noto Sans',
        fontWeight: '400',
    };

    colorPalette = [
        '#00FFA7',
        '#24D9D9',
        '#A4BF00',
        '#FFB800',
        '#FF8A00',
        '#EE5843',
        '#F9748C',
        '#FFACBB',
        '#DB78D1',
        '#E7A5FF',
        '#8819CC',
        '#6819CC',
        '#5A00FF',
        '#4968D4',
        '#0085FF',
        '#6597C6',
    ];
    styleScrollbar(scrollbar: am5.Scrollbar) {
        scrollbar.thumb.setAll({
            fill: am5.color('#fff'),
            height: 1,
            width: 1,
        });

        const gripSettings: Partial<am5.IButtonSettings> = {
            height: 16,
            width: 16,
            icon: null,
        };
        scrollbar.startGrip.setAll(gripSettings);
        scrollbar.endGrip.setAll(gripSettings);
    }
}

type XYChartCreateSeriesArgs = ICreateSeriesArgs<
    Omit<am5xy.IColumnSeriesSettings, 'xAxis' | 'yAxis'> | Omit<am5xy.ILineSeriesSettings, 'xAxis' | 'yAxis'>
>;
