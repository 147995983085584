import { TableColumnsType } from 'antd';
import { ColumnType } from 'antd/es/table';
import { IStudy } from 'common/entities';
import useStudyTable from 'common/hooks/useStudyTable';
import { GDataGrid, IDataGridProps } from 'components/GDataGrid';
import Styles from './Styles.module.scss';

export default function StudyTable({ studyList, extraColumns, excludeColumns, columns, ...props }: IStudyTable) {
    let { tableColumns } = useStudyTable(studyList);

    extraColumns?.forEach(col => tableColumns.splice(col.order, 0, col.column));
    if (excludeColumns?.length) {
        tableColumns = tableColumns?.filter((column: any) => !excludeColumns.includes(column.dataIndex));
    }

    return (
        <GDataGrid
            dataSource={studyList}
            columns={columns ?? tableColumns}
            rowKey="study_id"
            className={Styles.StudyTable}
            {...props}
        />
    );
}

interface IStudyTable extends IDataGridProps<IStudy> {
    studyList: Array<IStudy>;
    extraColumns?: Array<IExtraColumn>;
    excludeColumns?: Array<string>;
    columns?: TableColumnsType;
}

interface IExtraColumn {
    column: ColumnType<IStudy>;
    order?: number;
}
