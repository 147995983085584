import { GFallback } from 'components';
import { Suspense, lazy } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './Matomo';
import NavigationProvider from './NavigationProvider';
import GLayout from './components/GLayout';

export default function RouterProviderWrapper() {
    return (
        <Suspense fallback={<GFallback allScreen />}>
            <RouterProvider
                router={route}
                fallbackElement={<GFallback allScreen />}
            />
        </Suspense>
    );
}

const Main = lazy(() => import(/* webpackChunkName: 'Main_Page', webpackPrefetch: true */ 'pages/Main/index'));

const Project = lazy(() => import(/* webpackChunkName: 'Project_Page', webpackPrefetch: true */ 'pages/Project/index'));
const ProjectDetails = lazy(
    () => import(/* webpackChunkName: 'Project_Details_Page', webpackPrefetch: true */ 'pages/Project/views/ProjectDetails/index')
);

const Validation = lazy(() => import(/* webpackChunkName: 'Validation_Page', webpackPrefetch: true */ 'pages/Validation/index'));
const Playground = lazy(() => import(/* webpackChunkName: 'Playground_Page' */ 'pages/Playground/index'));
const User = lazy(() => import(/* webpackChunkName: 'User_Page' */ 'pages/User/index'));
const Platform = lazy(() => import(/* webpackChunkName: 'Platform_Page' */ 'pages/Platform/index'));
const Page404 = lazy(() => import(/* webpackChunkName: 'Page404_Page' */ 'app/components/404Page/index'));
const Viewer3D = lazy(() => import(/* webpackChunkName: 'Viewer3D_Page' */ 'pages/Viewer3D/index'));

const Models = lazy(() => import(/* webpackChunkName: 'Models_Page' */ 'pages/Models/index'));
const ModelDetails = lazy(() => import(/* webpackChunkName: 'Model_Details' */ 'pages/Models/views/ModelDetails/index'));
const ModelContent = lazy(
    () => import(/* webpackChunkName: 'Model_Details_Content' */ 'pages/Models/views/ModelDetails/components/ModelContent')
);

const Datasets = lazy(() => import(/* webpackChunkName: 'Datasets_Page' */ 'pages/Datasets/index'));
const DatasetDetails = lazy(() => import(/* webpackChunkName: 'Model_Details' */ 'pages/Datasets/views/DatasetDetails/index'));
const DatasetContent = lazy(
    () => import(/* webpackChunkName: 'Model_Details_Content' */ 'pages/Datasets/views/DatasetDetails/components/DatasetContent')
);

const Annotation = lazy(() => import(/* webpackChunkName: 'Annotation_Page' */ 'pages/AnnotationCS/index'));

const AnnotationViewer = lazy(() => import(/* webpackChunkName: 'Annotation_Page' */ 'pages/AnnotationCS/views/ToolView/index'));
const AnnotationViewer3D = lazy(
    () => import(/* webpackChunkName: 'Annotation_Page' */ 'pages/AnnotationCS/views/Viewer3D/index')
);

const AnnotationDetails = lazy(
    () => import(/* webpackChunkName: 'Annotation_Page' */ 'pages/AnnotationCS/views/AnnotationDetails/index')
);

const Authentication = lazy(() => import(/* webpackChunkName: 'Authentication_Page' */ 'pages/Authentication/index'));
const AuthLogin = lazy(() => import(/* webpackChunkName: 'Authentication_Page' */ 'pages/Authentication/views/Login/index'));
const AuthRegister = lazy(
    () => import(/* webpackChunkName: 'Authentication_Page' */ 'pages/Authentication/views/Register/index')
);
const ForgotPassword = lazy(
    () => import(/* webpackChunkName: 'Authentication_Page' */ 'pages/Authentication/views/ForgotPassword/index')
);
const AuthNewPassword = lazy(
    () => import(/* webpackChunkName: 'Authentication_Page' */ 'pages/Authentication/views/NewPassword/index')
);
const route = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<NavigationProvider />}>
            <Route
                path="/"
                element={<GLayout />}
            >
                <Route
                    index
                    element={<Main />}
                />
                <Route
                    path="main"
                    element={<Main />}
                />
                <Route
                    path="main/:activeView"
                    element={<Main />}
                />

                <Route
                    path="project/:activeView?"
                    element={<Project />}
                />
                <Route
                    path="project/project-details/:projectId?/:projectActiveView?"
                    element={<ProjectDetails />}
                />

                <Route
                    path="validation/:activeView?/:validationId?/:subView?"
                    element={<Validation />}
                />

                <Route
                    path="models/:activeView?"
                    element={<Models />}
                />

                <Route
                    path="models/model-details"
                    element={<ModelDetails />}
                >
                    <Route
                        path=":modelId"
                        element={<ModelContent />}
                    />
                    <Route
                        path=":modelId/:modelDetailsActiveView"
                        element={<ModelContent />}
                    />
                </Route>

                <Route
                    path="data/:activeView?/:curationStep?"
                    element={<Datasets />}
                />

                <Route
                    path="data/:activeView/:datasetId?/:analysisActiveView?"
                    element={<Datasets />}
                />

                <Route
                    path="data/dataset-details"
                    element={<DatasetDetails />}
                >
                    <Route
                        path=":datasetId?/:datasetDetailActiveTabKey?"
                        element={<DatasetContent />}
                    />
                </Route>

                <Route
                    path="playground"
                    element={<Playground />}
                />

                <Route
                    path="viewer/:mode"
                    element={<Viewer3D />}
                />

                <Route
                    path="viewer/:mode/:dataset_id?"
                    element={<Viewer3D />}
                />

                <Route
                    path="annotation/annotation-details/:projectId?/:annotationDetailsActiveView?/:agreementActiveKey?"
                    element={<AnnotationDetails />}
                />

                <Route
                    path="annotation/annotation-viewer-3D/:projectId?/"
                    element={<AnnotationViewer3D />}
                />

                <Route
                    path="annotation/annotation-viewer/:projectId?/"
                    element={<AnnotationViewer />}
                />

                <Route
                    path="annotation/:activeView?"
                    element={<Annotation />}
                />

                <Route
                    path="user/:location?/:activeView?"
                    element={<User />}
                />

                <Route
                    path="platform/:activeView?"
                    element={<Platform />}
                />
            </Route>

            <Route
                path="*"
                element={<Page404 />}
            />

            <Route
                path="auth"
                element={<Authentication />}
            >
                <Route
                    path="login"
                    element={<AuthLogin />}
                />
                <Route
                    path="register"
                    element={<AuthRegister />}
                />
                <Route
                    path="forgot-password"
                    element={<ForgotPassword />}
                />
                <Route
                    path="new-password/:token"
                    element={<AuthNewPassword />}
                />
            </Route>
        </Route>
    )
);
