import { Dropdown, DropDownProps, Menu } from 'antd';
import cx from 'classnames';
import React from 'react';
import Styles from './Styles.module.scss';

export default function GDropdown({ onOpenChange, className, overlayClassName, ...props }: IDropDownProps) {
    const overlay = (
        <Menu
            items={props.menuItemList
                ?.filter(i => !i.hidden)
                .map(item => ({
                    key: item.key,
                    label: item.render,
                    disabled: item.disabled,
                    icon: item.icon,
                }))}
        />
    );

    return (
        <div
            className={cx(Styles.DropdownContainer, className)}
            id={props.id}
        >
            <Dropdown
                onOpenChange={onOpenChange}
                overlayClassName={cx(Styles.DropdownMenu, overlayClassName)}
                overlay={overlay}
                {...props}
            >
                {props.children}
            </Dropdown>
        </div>
    );
}

export const GDropdownClassName = Styles.DropdownContainer;
export const GDropdownMenuClassName = Styles.DropdownMenu;

interface IDropDownProps extends Partial<DropDownProps> {
    children: React.ReactNode;
    dropdownRender?: (menu: React.ReactNode) => React.ReactNode;
    menuItemList?: Array<IDropdownMenuItem>;
    overlayClassName?: string;
    placement?: TPlacement;
    id?: string;
}
export interface IDropdownMenuItem {
    key: string;
    render: React.ReactNode;
    disabled?: boolean;
    icon?: React.ReactNode;
    hidden?: boolean;
}

export type TPlacement = 'bottomLeft' | 'bottom' | 'top' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter';
