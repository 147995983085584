import { IUserInfo } from 'pages/Authentication/entities';

export enum MigrationProcessStatus {
    PENDING = 'pending',
    IN_PROGRESS = 'in-progress',
    COMPLETED = 'completed',
    FAILED = 'failed',
    STOPPED = 'stopped',
}

export interface PacsMigration {
    name: string;
    user_id: string;
    status: MigrationProcessStatus;
    message: string;
    source: SourceType;
    source_details: CloudStorage;
    label: string;
    api_name: string;
    filter?: MigrationFilter;
    completed_count: number;
    count: number;
    user?: IUserInfo;
    createdAt?: Date;
    study_list?: Array<string>;
    _id?: string;
}

export class MigrationFilter {
    extension: Array<string>;
}

export type SourceType = 's3' | 'gcs';

export interface CloudStorage {
    bucket: string;
    path: string;
    region: string;
    credentials?: any;
}

export * from './MigrationFile';

export const CLONE_MIGRATION_EVENT = 'CLONE_MIGRATION_EVENT';
