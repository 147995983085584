import cx from 'classnames';
import React from 'react';
import { StepButtons } from './StepButtons';
import Styles from './Styles.module.scss';
import { IStep, useStepActions } from './useStepActions';

export default function GSteps({
    className,
    steps,
    style,
    disableNext,
    children,
    hideButtons,
    showArrow,
    onSubmit,
    extraButton,
    ...props
}: IGStepsProps) {
    const { completedSteps, current, next, onClickStep, prev } = useStepActions({ ...props, disableNext });

    function renderStep(step: IStep, index: number) {
        const disabled =
            (!completedSteps?.includes(index) && current !== index && completedSteps[completedSteps.length - 1] !== index) ||
            disableNext;
        return (
            <>
                <div
                    className={cx('step', { active: index === current, disabled })}
                    // onClick={() => onClickStep(index, disabled)}
                    key={step?.title}
                >
                    {step.title}
                </div>
                {index !== steps?.length - 1 ? <hr className="solid" /> : null}
            </>
        );
    }

    return (
        <div
            className={cx(Styles.GStepsContainer, className)}
            style={style}
        >
            <div className="step-title">{steps?.map(renderStep)}</div>

            <div className="step-content">
                {steps[current].content}

                <StepButtons
                    next={next}
                    prev={prev}
                    disableNext={disableNext}
                    current={current}
                    hideButtons={hideButtons}
                    total={steps?.length}
                    showArrow={showArrow}
                    onSubmit={onSubmit}
                    extraButton={extraButton}
                />

                {children}
            </div>
        </div>
    );
}

export * from './StepButtons';

interface IGStepsProps {
    className?: string;
    style?: React.CSSProperties;
    steps?: Array<IStep>;
    currentStep?: number;
    onCurrentChange?: (current: number) => void;
    disableNext?: boolean;
    children?: React.ReactNode;
    hideButtons?: boolean;
    showArrow?: boolean;
    extraButton?: React.ReactNode | boolean;
    onSubmit?: () => void;
}
