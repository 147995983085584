import { DatasetEncoding, TabularExtention, VideoExtention } from 'common';

export interface IUploadParams {
    datasetId: string;
    uploadOptionType: TDatasetUploadOption;
    sourceType?: TSourceType;
    srcCredId: string;
    srcCloudName: TCloudNameOption;
    localPath: string;
    s3Params: IS3Params;
    encoding: DatasetEncoding;
    storeLocation: TDestination;
    importWithUrl: string;
    zipPath: string;
    srcUrl?: string;
    destCloudName?: string;
    destCredId?: string;
    dataType: "image" | "video" | "tabular";
    videoExt?: VideoExtention;
    datasetPicture: string;
    tabularExt?: TabularExtention;
}

export function UploadParamsFactory(data?: Partial<IUploadParams>): IUploadParams {
    return {
        datasetId: data?.datasetId || null,
        uploadOptionType: data?.uploadOptionType || "zip",
        srcCredId: data?.srcCredId || null,
        localPath: data?.localPath || null,
        encoding: data?.encoding || "jpg",
        storeLocation: data?.storeLocation || "local",
        s3Params: S3ParamsFactory(data?.s3Params),
        srcCloudName: data?.srcCloudName || null,
        sourceType: data?.sourceType || "upload",
        importWithUrl: data?.importWithUrl || null,
        zipPath: data?.zipPath || null,
        srcUrl: data?.srcUrl || null,
        destCloudName: data?.destCloudName || null,
        destCredId: data?.destCredId || null,
        dataType: data?.dataType || "image",
        videoExt: data?.videoExt || "PAL",
        datasetPicture: data?.datasetPicture || null,
        tabularExt: data?.tabularExt || "csv",
    };
}

export interface IS3Params {
    folderName: string;
    URI: string;
}

export function S3ParamsFactory(data?: Partial<IS3Params>): IS3Params {
    return {
        folderName: data?.folderName || null,
        URI: data?.URI || null,
    };
}

export type TDatasetUploadOption = "zip" | "path";
export type TDestination = "local" | "cloud" | "same";
export type TSourceType = "local" | "upload" | "cloud";
export type TCloudNameOption = "aws" | "azure" | "gcp" | 'gdrive' | 'wget' | 'dropbox';