import { Input, InputProps } from 'antd';
import React, { useCallback } from 'react';
import Styles from "./assets/Styles.module.scss";

export function GInput({ className, onInputChange, onChange, ...props }: GInputProps) {

    const onChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange?.(e.target?.value, e);
        onChange?.(e);
    }, [onChange, onInputChange]);


    return (
        <div className={[Styles.GInputContainer, className].join(" ")}>
            <Input onChange={onChangeValue} {...props} />
        </div>
    );
}

interface GInputProps extends InputProps {
    onInputChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
}

export * from "./GInputGroup";