import { Progress, ProgressProps } from "antd";
import Styles from "./Styles.module.scss";
import useSystemUtils from "./useSystemUtils";

export default function SystemUtilsOverlay() {

    const { progressMenuList } = useSystemUtils();

    return (
        <div className={Styles.ProgressContainer}>
            {progressMenuList.map((item) => <ProgressComponent key={item.label} label={item.label} value={item.value} />)}
        </div>
    );
}

function ProgressComponent({ label, value, }: { label: string, value: number, }) {
    return (
        <div className={Styles.ProgressBar} >
            <span className={["caption2-bold", Styles.Progresslabel].join(" ")}>{label}</span>
            <Progress {...progressProps} percent={value} />
        </div>
    );
}

const progressProps: ProgressProps = {
    strokeColor: {
        "0%": "#2CD7C4",
        "100%": "#503795",
    },
    trailColor: "#3B424E",
    status: "active",
    showInfo: true,
};