export interface IControlTool<T = DicomViewerControlTool> {
    key: T;
    title: string;
    button?: string;
    icon: React.ReactNode;
    selectable?: boolean;
}

export type DicomViewerControlTool =
    | 'StackScroll'
    | 'Wwwc'
    | 'Zoom'
    | 'Length'
    | 'Angle'
    | 'Pan'
    | 'Eraser'
    | 'RectangleRoi'
    | 'EllipticalRoi'
    | 'FreehandRoi'
    | 'ArrowAnnotate'
    | 'ScaleOverlay'
    | 'TextMarker'
    | 'Cine'
    | 'GridLayout'
    | 'Brush'
    | 'CobbAngle'
    | 'FreehandScissors'
    | 'CorrectionScissors'
    | 'BrushEraser'
    | 'Bidirectional'
    | 'FitToWindow'
    | 'Reset'
    | 'Invert'
    | 'HFlip'
    | 'VFlip'
    | 'RotateR'
    | 'RotateL'
    | 'CustomThreshold'
    | 'AssistedSliceSelectionRect'
    | 'AssistedSliceSelectionCross'
    | 'HideAllSegments'
    | 'ShowBrushToolBox'
    | 'SphericalGrowCutSegmentation'
    | 'Undo'
    | 'Redo'
    | 'ChangeVisibiliy'
    | 'SAMRectPrediction'
    | 'SAMPointPrediction'
    | 'Apply'
    | 'Download'
    | 'ReferenceLines';

export enum AnnotationToolEnum {
    Wwwc = 'Wwwc',
    Zoom = 'Zoom',
    Pan = 'Pan',
    Probe = 'Probe',
    Length = 'Length',
    Angle = 'Angle',
    RectangleRoi = 'RectangleRoi',
    EllipticalRoi = 'EllipticalRoi',
    FreeHandRoi = 'FreehandRoi',
}
