import cornerstoneTools from "cornerstone-tools";
const external = cornerstoneTools.external;

const BaseBrushTool = cornerstoneTools.importInternal("base/BaseBrushTool");
const segmentationUtils = cornerstoneTools.importInternal(
  "util/segmentationUtils"
);

const drawBrushPixels = segmentationUtils.drawBrushPixels;
const getCircle = segmentationUtils.getCircle;
const segmentationModule = cornerstoneTools.getModule("segmentation");

export class CustomThresholdTool extends BaseBrushTool {
    constructor(props = {}) {
      const defaultProps = {
        name: "CustomThreshold",
        supportedInteractionTypes: ["Mouse"],
        configuration: {},
        mixins: ["renderBrushMixin"],
      };
  
      super(props, defaultProps);
    }
  
    _paint(evt:any) {
      const { configuration } = segmentationModule;
      const eventData = evt.detail;
      const { rows, columns } = eventData.image;
      const { x, y } = eventData.currentPoints.image;
  
      if (x < 0 || x > columns || y < 0 || y > rows) {
        return;
      }
      // TODO: Make this configurable
      configuration.thresholds = [400, 3000];
      const radius = configuration.radius;
      const thresholds = configuration.thresholds;
      const { labelmap2D, labelmap3D, shouldErase } = this.paintEventData;
  
      let pointerArray = [];
  
      if (shouldErase) {
        pointerArray = getCircle(radius, rows, columns, x, y);
      } else {
        pointerArray = getCircleWithThreshold(
          eventData.image,
          radius,
          thresholds,
          x,
          y
        );
      }
  
      drawBrushPixels(
        pointerArray,
        labelmap2D.pixelData,
        labelmap3D.activeSegmentIndex,
        columns,
        shouldErase
      );
  
      external.cornerstone.updateImage(evt.detail.element);
    }
  }
  
  function getCircleWithThreshold(
    image:any,
    radius:any,
    thresholds:any,
    xCoord = 0,
    yCoord = 0
  ) {
    const pixelData = image.getPixelData();
    const { rows, columns } = image;
    const x0 = Math.floor(xCoord);
    const y0 = Math.floor(yCoord);
    let circleArray : any[]= [];
  
    if (!thresholds) {
      thresholds = [image.minPixelValue, image.maxPixelValue];
    }
  
    function isInsideThresholds(v:any, t:any) {
      return v >= t[0] && v <= t[1];
    }
  
    if (radius === 1) {
      let value = pixelData[y0 * rows + x0];
      let moValue = value * image.slope + image.intercept;
      if (isInsideThresholds(moValue, thresholds)) {
        circleArray = [[x0, y0]];
      }
      return circleArray;
    }
  
    let index = 0;
  
    for (let y = -radius; y <= radius; y++) {
      const yCoord = y0 + y;
  
      if (yCoord > rows || yCoord < 0) {
        continue;
      }
  
      for (let x = -radius; x <= radius; x++) {
        const xCoord = x0 + x;
  
        if (xCoord >= columns || xCoord < 0) {
          continue;
        }
  
        let value = pixelData[yCoord * rows + xCoord];
        let moValue = value * image.slope + image.intercept;
  
        if (
          x * x + y * y < radius * radius &&
          isInsideThresholds(moValue, thresholds)
        ) {
          circleArray[index++] = [x0 + x, y0 + y];
        }
      }
    }
  
    return circleArray;
  }