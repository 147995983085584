import { _compact, _isEmpty, _uuid, _values, isNullOrUndefined } from 'common/Utils';
import { getRandomSegmentationColor } from 'components/assets/theme';
import { cornerstone, cornerstoneTools } from '../CornerstoneInitHelper/CornerstoneHelper';
import { hexToRgb } from '../SegmentationHelper/GenericUtils';
import { DicomViewerCore } from './DicomViewerCore';
import {
    BrushToolState,
    Color,
    Getters,
    ICreateSegmentListArgs,
    IHistoryOperation,
    LabelMap2DReturnType,
    LabelMap3D,
    LabelMaps3DReturnType,
    ReplaceMode,
    Segmentation,
    SegmentationMetadataFactory,
} from './interface';

const segmentationUtils = cornerstoneTools.importInternal('util/segmentationUtils');

export abstract class SegmentationUtils extends DicomViewerCore {
    protected currentSegmentLabel: string;

    getSegmentLabelMap(viewport: number | HTMLElement = this.activeElementIndex) {
        const segmentLabelMap = new Map<string, Segmentation>();

        const element = typeof viewport === 'number' ? this.getViewportElement(viewport) : viewport;
        if (!this.isElementEnabled(element)) return segmentLabelMap;

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
        if (!labelmaps3D) return segmentLabelMap;

        labelmaps3D.forEach((labelmap3D, i) => {
            if (!labelmap3D) return;
            const metadata = labelmap3D?.metadata ?? null;
            const colorLutTable = this.segmentationState.colorLutTables[labelmap3D.colorLUTIndex];

            _compact(metadata).forEach(meta => {
                const color = colorLutTable[meta.SegmentNumber];

                segmentLabelMap.set(meta.SegmentLabel, {
                    id: i + '+' + meta.SegmentNumber,
                    labelmapIndex: i,
                    segmentIndex: meta.SegmentNumber,
                    color,
                    meta,
                    name: meta.SegmentLabel,
                    description: meta.SegmentDescription,
                });
            });
        });

        return segmentLabelMap;
    }

    public labelMapIndexMap = new Map<string, number>();
    getLabelMapIndex(labelMapKey: string, viewportIndex = this.activeElementIndex): number {
        if (this.labelMapIndexMap.has(labelMapKey)) return this.labelMapIndexMap.get(labelMapKey);
        let _newIndex = this.labelMapIndexMap.size;

        const element = this.getViewportElement(viewportIndex);
        if (this.isElementEnabled(element)) {
            const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
            if (labelmaps3D) _newIndex = labelmaps3D.length;
        }

        this.labelMapIndexMap.set(labelMapKey, _newIndex);
        return _newIndex;
    }

    getLabelmapKeyFromIndex(index: number): string {
        let result;
        this.labelMapIndexMap.forEach((value, key) => {
            if (value === index) result = key;
        });
        return result;
    }

    populateLabelMapIndexMap() {
        _values(this.segmentationState?.series).forEach(item => {
            item.labelmaps3D?.forEach((labelmap3D, i) => {
                if (!labelmap3D?.key) return;
                if (!this.getLabelmapKeyFromIndex(i)) this.labelMapIndexMap.set(labelmap3D.key, i);
            });
        });
    }

    elementWaitedMethods = new Map<string, { name: string; args: any[] }>();
    applyElementWaitedMethods() {
        this.elementWaitedMethods.forEach(({ name, args = [] }) => {
            (this as any)[name](...args);
        });
    }

    createSegmentList(args: ICreateSegmentListArgs, functionId = _uuid()) {
        try {
            let { colorMap, labelList, labelMapKey, onColorList, seriesId, viewportIndex = this.activeElementIndex } = args;
            if (!labelList?.length) return;
            const element = this.getViewportElement(viewportIndex);
            const image = this.getEnabledElement(element)?.image;

            const labelMapIndex = this.getLabelMapIndex(labelMapKey, viewportIndex);

            if (!element || !image || (seriesId && this.getSeriesIdFromMetadata(image) !== seriesId)) {
                this.elementWaitedMethods.set(functionId, {
                    name: 'createSegmentList',
                    args: [args, functionId],
                });
                return labelMapIndex;
            }

            this.setters.activeLabelmapIndex(element, labelMapIndex);

            const colorList = labelList.map((label, i) => {
                this.setters.activeSegmentIndex(element, i);

                return {
                    color: colorMap?.[label]
                        ? hexToRgb(colorMap[label])
                        : hexToRgb(getRandomSegmentationColor(_values(colorMap))),
                    label,
                };
            });

            this.setters.colorLUT(
                labelMapIndex,
                colorList.map(({ color }) => [..._values(color), 120])
            );

            labelList.forEach((label, i) => {
                const index = i + 1;
                const metadata = SegmentationMetadataFactory({
                    SegmentNumber: index,
                    SegmentLabel: label,
                    SegmentDescription: label,
                });

                this.setters.metadata(element, labelMapIndex, index, metadata);

                this.setters.activeSegmentIndex(element, index);
            });
            const { labelmap3D } = this.getLabelMap2D(element);

            labelmap3D.colorLUTIndex = labelMapIndex;
            labelmap3D.key = labelMapKey;

            onColorList?.(colorList);

            if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);

            return labelMapIndex;
        } catch (error) {
            console.error(error);
        }
    }

    clearSegmentations(viewportIndex?: number): void {
        if (_isEmpty(viewportIndex)) return this.elementMap.forEach((_, index) => this.clearSegmentations(index));

        const element = this.getViewportElement(viewportIndex);
        this.getters.labelmaps3D(element)?.labelmaps3D?.forEach((labelmap3D: LabelMap3D) => {
            labelmap3D?.labelmaps2D.forEach(labelmap2D => {
                if (!labelmap2D) return;
                labelmap2D.segmentsOnLabelmap = [0];
                labelmap2D.pixelData = new Float32Array(labelmap2D.pixelData.length);
            });
        });
    }

    resetElementToolState(viewportIndex: number = this.activeElementIndex) {
        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(element)) return;
        const enabledElement = this.getEnabledElement(element) as any;

        enabledElement.toolStateManager = cornerstoneTools.newImageIdSpecificToolStateManager();
    }

    calculateSegmentArea(pixelData: Float32Array | Array<number>, label: number, spacing: Array<number>) {
        const pixelCount = pixelData.filter(pixel => pixel === label).length;
        const areaInMm2 = pixelCount * (spacing[0] * spacing[1]);
        return areaInMm2;
    }

    filterLabelPixels(pixelData: Float32Array, segmentIndex: number): Float32Array {
        return pixelData.map(pixel => (pixel === segmentIndex ? pixel : 0));
    }

    clearSegmentationFromLabelmap(element: HTMLDivElement, labelmapindex: number, segmentIndex: number) {
        if (_isEmpty(element, labelmapindex, segmentIndex)) return;

        try {
            const labelmap3D: LabelMap3D = this.getters.labelmap3D(element, labelmapindex);
            if (!labelmap3D) return;

            labelmap3D?.labelmaps2D?.forEach((labelmap2D, i) => {
                if (!labelmap2D?.segmentsOnLabelmap?.includes(segmentIndex)) return;

                const previousPixelData = structuredClone(labelmap2D.pixelData);

                for (let i = 0; i < labelmap2D.pixelData.length; i++) {
                    if (labelmap2D.pixelData[i] === segmentIndex) {
                        labelmap2D.pixelData[i] = 0;
                    }
                }

                const operation = {
                    imageIdIndex: i,
                    diff: segmentationUtils.getDiffBetweenPixelData(previousPixelData, labelmap2D.pixelData),
                };

                this.segmentationModule.setters.pushState(element, [operation]);

                this.segmentationModule.setters.updateSegmentsOnLabelmap2D(labelmap2D);
            });
        } catch (error) {
            console.error(error);
        }
    }

    changeBrushRadius(_radius: number) {
        this.configuration.radius = _radius;
    }

    changeActiveSegmentOpacity(opacity: number) {
        this.brushState.forEach(labelmap3D => {
            const segmentIndex = labelmap3D.metadata.find(meta => meta?.SegmentLabel === this.currentSegmentLabel)?.SegmentNumber;
            const colorLut = this.segmentationState.colorLutTables[labelmap3D.colorLUTIndex];

            if (_isEmpty(segmentIndex)) return;

            colorLut[segmentIndex][3] = opacity;
        });
    }

    //mask encoding as a string
    maskToRle(image: Float32Array): string {
        let result = '';

        let i = 0;
        while (i < image.length) {
            if (image[i] === 0) {
                i++;
                continue;
            }
            let j = i + 1;
            let count = 1;
            while (image[i] === image[j]) {
                count++;
                j++;
            }
            result = result.concat(`${i} ${count} `);
            i = j;
        }
        return result.trimEnd();
    }

    //rle decoding
    rleToMask(mask_rle: string, length: number, label: number = 0): Float32Array {
        const mask = new Float32Array(length);

        if (_isEmpty(mask_rle)) return mask;

        const rleList = mask_rle.split(' ');
        rleList.forEach((item, i) => {
            if (i % 2 !== 0) return;
            const start = Number(item);
            const length = Number(rleList[i + 1]);

            for (let j = start; j < start + length; j++) {
                mask[j] = label;

                if (j >= mask.length) break;
            }
        });

        return mask;
    }

    mergePixelDataLabels(pixelDataList: Array<Float32Array>, length: number) {
        const mergedPixelData = new Float32Array(length);

        for (let i = 0; i < length; i++) {
            mergedPixelData[i] = 0;

            pixelDataList.forEach(pixelData => {
                if (pixelData[i] !== 0) {
                    mergedPixelData[i] = pixelData[i];
                }
            });
        }
        return mergedPixelData;
    }

    exportAsSegModalityDcm(element: HTMLDivElement) {
        const stacktoolState = cornerstoneTools?.getToolState(element, 'stack');
        const imageIds: Array<string> = stacktoolState.data[0].imageIds;

        let imagePromises = imageIds?.map(image => cornerstone.loadImage(image));

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);

        if (!labelmaps3D) return console.log('No labelmaps3D found for element');

        labelmaps3D.forEach(labelmap3D => {
            const labelmaps2D = labelmap3D.labelmaps2D;

            labelmaps2D.forEach(labelmap2D => {
                labelmap2D?.segmentsOnLabelmap?.forEach((segmentIndex: any) => {
                    if (segmentIndex !== 0 && !labelmap3D.metadata[segmentIndex]) {
                        labelmap3D.metadata[segmentIndex] = this.generateMockMetadata(segmentIndex);
                    }
                });
            });
        });

        Promise.all(imagePromises)
            .then(images => {
                //const segBlob = this.dcmjs.adapters.Cornerstone.Segmentation.generateSegmentation(images, labelmaps3D);
                //Create a URL for the binary.
                //var objectUrl = URL.createObjectURL(segBlob);
                //              window.open(objectUrl);
            })
            .catch(err => console.log(err));
    }

    generateMockMetadata(segmentIndex: any) {
        return {
            SegmentedPropertyCategoryCodeSequence: {
                CodeValue: 'T-D0050',
                CodingSchemeDesignator: 'SRT',
                CodeMeaning: 'Tissue',
            },
            SegmentNumber: (segmentIndex + 1).toString(),
            SegmentLabel: 'Tissue ' + (segmentIndex + 1).toString(),
            SegmentAlgorithmType: 'SEMIAUTOMATIC',
            SegmentAlgorithmName: 'Gesund AI',
            SegmentedPropertyTypeCodeSequence: {
                CodeValue: 'T-D0050',
                CodingSchemeDesignator: 'SRT',
                CodeMeaning: 'Tissue',
            },
        };
    }

    hideAllSegments() {
        this.elementMap.forEach((element, index) => {
            const { labelmap3D } = this.getLabelMap2D(element);

            if (!labelmap3D?.metadata) return;

            labelmap3D.metadata
                .map(meta => meta.SegmentNumber)
                .forEach(index => {
                    labelmap3D.segmentsHidden[index] = !labelmap3D.segmentsHidden[index];
                });

            this.forceRender(index);
        });
    }

    hideOrShowActiveSegment(segmentIndex: number) {
        this.elementMap.forEach(element => {
            const labelmaps3D = this.getLabelMap2D(element);
            const labelmap3D = labelmaps3D.labelmap3D;
            const segmentsHidden = labelmap3D.segmentsHidden;

            if (!labelmap3D) return;
            if (!labelmap3D.metadata) return;

            const SegmentIndex = labelmap3D.metadata.find(item => item.SegmentNumber === segmentIndex);
            if (!SegmentIndex) return;
            segmentsHidden[segmentIndex] = !segmentsHidden[segmentIndex];
        });

        this.forceRender();
    }

    hideOrShowActiveSegmentById(label: string, visible?: boolean) {
        try {
            this.elementMap.forEach(element => {
                if (!this.isElementEnabled(element)) return;
                const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);

                labelmaps3D?.forEach(labelmap3D => {
                    const segmentsHidden = labelmap3D.segmentsHidden;

                    const Segment = labelmap3D?.metadata?.find(item => item?.SegmentLabel === label);
                    if (!Segment) return;
                    let number = Segment.SegmentNumber;
                    segmentsHidden[number] = !(visible ?? segmentsHidden[number]);
                });
            });

            this.forceRender();
        } catch (error) {
            console.log(error);
        }
    }

    undoStateSegmentation() {
        const labelmap3D = this.getters.labelmap3D(this.activeElement);
        const { undo, redo } = labelmap3D;

        if (!undo.length) return;

        // Pop last set of operations from undo.
        const operations = undo.pop();

        // Undo operations.
        this.applyState(labelmap3D, operations, ReplaceMode.undo);

        // Push set of operations to redo.
        redo.push(operations);

        segmentationUtils.triggerLabelmapModifiedEvent(this.activeElement);
        this.forceRender();
    }

    redoStateSegmentation() {
        const labelmap3D = this.getters.labelmap3D(this.activeElement);
        const { undo, redo } = labelmap3D;

        if (!redo.length) return;

        // Pop last set of operations from undo.
        const operations = redo.pop();

        // Undo operations.
        this.applyState(labelmap3D, operations, ReplaceMode.redo);

        // Push set of operations to redo.
        undo.push(operations);

        segmentationUtils.triggerLabelmapModifiedEvent(this.activeElement);
        this.forceRender();
    }

    applyState(labelmap3D: LabelMap3D, operations: Array<IHistoryOperation>, replaceIndex: ReplaceMode) {
        const { labelmaps2D } = labelmap3D;

        operations.forEach(({ diff, imageIdIndex }) => {
            const labelmap2D = labelmaps2D[imageIdIndex];

            for (const diffI of diff) {
                labelmap2D.pixelData[diffI[0]] = diffI[replaceIndex];
            }

            this.setters.updateSegmentsOnLabelmap2D(labelmap2D);
        });
    }

    changeActiveSegmentColor(segmentName: string, color: Color) {
        const labelmaps3D = this.getLabelMap2D(this.activeElement);
        const labelmap3D = labelmaps3D.labelmap3D;
        const SegmentIndex = labelmap3D?.metadata
            .filter((i: any) => !isNullOrUndefined(i))
            ?.find(item => item.SegmentLabel === segmentName);

        if (!SegmentIndex) return;
        let changeIndex = SegmentIndex.SegmentNumber;
        const colorLut = this.segmentationState.colorLutTables[0];
        colorLut[changeIndex][0] = color.r;
        colorLut[changeIndex][1] = color.g;
        colorLut[changeIndex][2] = color.b;

        this.forceRender();
    }

    changeSegmentLabelByName(nextLabel: string, oldLabel: string) {
        const labelmaps3D = this.getLabelMap2D(this.activeElement);

        const SegmentIndex = labelmaps3D.labelmap3D.metadata.find(function (item: any) {
            return item.SegmentLabel === oldLabel;
        });

        if (!SegmentIndex) return;

        SegmentIndex.SegmentLabel = nextLabel;

        this.forceRender();
    }

    setActiveSegmentVisible(segmentOpacity: number) {
        const element = this.activeElement;
        const labelmap3D = this.getLabelMap2D(element)?.labelmap3D;
        const activeLabelmapIndex: number = this.getters.activeLabelmapIndex(element);
        const activeIndex: number = this.getters.activeSegmentIndex(element);
        const colorLut = this.segmentationState.colorLutTables[activeLabelmapIndex];

        const Segments = labelmap3D.metadata.map((item: any) => {
            return item.SegmentNumber;
        });

        Segments.forEach((index: any) => {
            if (index !== activeIndex) {
                colorLut[index][3] = 30;
            } else {
                colorLut[activeIndex][3] = segmentOpacity;
            }
        });
    }

    addLabelmap2D(element: HTMLElement, labelmapIndex: number, imageIdIndex: number, length: number) {
        const elementOffset = length * imageIdIndex;

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
        if (!labelmaps3D?.[labelmapIndex]) return null;

        const pixelData = new Float32Array(
            labelmaps3D[labelmapIndex].buffer,
            elementOffset * 4, // 4 bytes/voxel
            length
        );

        labelmaps3D[labelmapIndex].labelmaps2D[imageIdIndex] = {
            pixelData,
            segmentsOnLabelmap: [],
        };

        return labelmaps3D[labelmapIndex].labelmaps2D[imageIdIndex];
    }

    resetBrushToolState() {
        _values(this.segmentationState.series).forEach(seriesState => {
            seriesState.labelmaps3D.forEach(labelmap3D => {
                labelmap3D.labelmaps2D.forEach(labelmap2D => {
                    if (!labelmap2D) return;
                    labelmap2D.pixelData = new Float32Array(labelmap2D.pixelData.length);
                    labelmap2D.segmentsOnLabelmap = [0];
                });
            });
        });
    }

    getSeriesModule(image: cornerstone.Image): Dictionary {
        return cornerstone.metaData?.get('generalSeriesModule', image?.imageId);
    }

    getImageModule(image: cornerstone.Image): Dictionary {
        return cornerstone.metaData?.get('generalImageModule', image?.imageId);
    }

    getSeriesIdFromMetadata(image: cornerstone.Image): string {
        return this.getSeriesModule(image)?.seriesInstanceUID;
    }

    getInstanceIdFromMetadata(image: cornerstone.Image): string {
        return this.getImageModule(image)?.sopInstanceUid;
    }

    get segmentationModule() {
        return cornerstoneTools?.getModule('segmentation');
    }

    get getters(): Getters {
        return this.segmentationModule?.getters;
    }

    get setters() {
        return this.segmentationModule?.setters;
    }

    get segmentationState(): BrushToolState {
        return this.segmentationModule?.state;
    }

    get configuration() {
        return this.segmentationModule?.configuration;
    }

    get brushState(): Array<LabelMap3D> {
        return _values(this.segmentationState?.series).reduce((acc, curr: LabelMaps3DReturnType) => {
            curr.labelmaps3D?.forEach((labelmap, i) => {
                if (!labelmap) return;
                acc[i] = labelmap;
            });
            return acc;
        }, []);
    }

    getLabelMap2D(element: HTMLElement): LabelMap2DReturnType {
        return this.getters.labelmap2D(element);
    }
}
