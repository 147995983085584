import { RootState } from "redux/store";

export const selectDatasetUploadEncoding = (state: RootState) => state.datasets.datasetUploadState.uploadParams.encoding;
export const selectUploadDatasetId = (state: RootState) => state.datasets.datasetUploadState.uploadParams.datasetId;
export const selectUploadOptionType = (state: RootState) => state.datasets.datasetUploadState.uploadParams.uploadOptionType;
export const selectSourceType = (state: RootState) => state.datasets.datasetUploadState.uploadParams.sourceType;
export const selectSrcCredId = (state: RootState) => state.datasets.datasetUploadState.uploadParams.srcCredId;
export const selectSrcCloudName = (state: RootState) => state.datasets.datasetUploadState.uploadParams.srcCloudName;
export const selectSrcUrl = (state: RootState) => state.datasets.datasetUploadState.uploadParams.srcUrl;
export const selectStoreLocation = (state: RootState) => state.datasets.datasetUploadState.uploadParams.storeLocation;
export const selectS3Params = (state: RootState) => state.datasets.datasetUploadState.uploadParams.s3Params;
export const selectLocalPath = (state: RootState) => state.datasets.datasetUploadState.uploadParams.localPath;
export const selectDatasetUploadLoading = (state: RootState) => state.datasets.datasetUploadState.uploadLoading;
export const selectImportWithUrl = (state: RootState) => state.datasets.datasetUploadState.uploadParams.importWithUrl;
export const selectDestCloudName = (state: RootState) => state.datasets.datasetUploadState.uploadParams.destCloudName;
export const selectDestCredId = (state: RootState) => state.datasets.datasetUploadState.uploadParams.destCredId;
export const selectDataType = (state: RootState) => state.datasets.datasetUploadState.uploadParams.dataType;
export const selectVideoExt = (state: RootState) => state.datasets.datasetUploadState.uploadParams.videoExt;
export const selectTabularExt = (state: RootState) => state.datasets.datasetUploadState.uploadParams.tabularExt;

export const selectDatasetUploadParams = (state: RootState) => state.datasets.datasetUploadState.uploadParams;
export const selectDatasetUploadState = (state: RootState) => state.datasets.datasetUploadState;
export const selectThumbnailList = (state: RootState) => state.datasets.datasetUploadState.thumbnailList;
export const selectUploadStatus = (state: RootState) => state.datasets.datasetUploadState.uploadStatus;
export const selectUploadProgress = (state: RootState) => state.datasets.datasetUploadState.uploadProgress;