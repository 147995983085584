import { Form, FormProps } from 'antd';
import { getMessages } from 'common/helpers';
import Styles from './Styles.module.scss';
import classnames from 'classnames';
import GButton from 'components/GButton';

export default function GForm({
    className,
    cancelButton,
    children,
    submitDisabled,
    submitLoading,
    onCancel,
    submitButton,
    form,
    submitButtonText,
    submitButtonId,
    submitButtonSize,
    cancelButtonSize,
    cancelButtonText,
    cancelButtonType,
    ...props
}: IGFormProps) {
    function onSubmit(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (form) {
            form?.submit();
            e.preventDefault();
        }
    }

    return (
        <div className={[Styles.FormContainer, className].join(' ')}>
            <Form
                form={form}
                {...props}
            >
                <>
                    {children}
                    {(cancelButton || submitButton) && (
                        <div className="row">
                            <Form.Item className="button-wrapper">
                                <div className="button-group">
                                    {cancelButton && (
                                        <GButton
                                            onClick={onCancel}
                                            htmlType="reset"
                                            type={cancelButtonType ?? 'default'}
                                            className="button cancelButton"
                                            size={cancelButtonSize ||"middle"}
                                        >
                                            {cancelButtonText ?? getMessages('000238')}
                                        </GButton>
                                    )}

                                    {submitButton && (
                                        <GButton
                                            disabled={submitDisabled || submitLoading}
                                            htmlType="submit"
                                            type="primary"
                                            loading={submitLoading}
                                            className={classnames('button', { 'button-disabled': submitDisabled })}
                                            onClick={onSubmit}
                                            id={submitButtonId}
                                            size={submitButtonSize ||"middle"}
                                        >
                                            {submitButtonText ?? getMessages('000169')}
                                        </GButton>
                                    )}
                                </div>
                            </Form.Item>
                        </div>
                    )}
                </>
            </Form>
        </div>
    );
}

export interface IGFormProps extends FormProps {
    cancelButton?: boolean;
    submitButton?: boolean;
    submitDisabled?: boolean;
    onCancel?: () => void;
    submitLoading?: boolean;
    submitButtonText?: string;
    submitButtonId?: string;
    submitButtonSize?: 'middle' | 'large' | 'small';
    cancelButtonSize?: 'middle' | 'large' | 'small';
    cancelButtonText?: string;
    cancelButtonType?: "link" | "primary" | "default" | "text" | "ghost" | "dashed";
}
