import { APISlice } from 'app/store/APISlice';
import { _compact, _compactDict, _entries, _isEmpty } from 'common/Utils';
import { Dictionary, IImage, IImageResponse, IStudy } from 'common/entities';
import { ServiceEnum } from 'common/enum';
import { transformImages } from 'common/services';
import {
    IAgreementFields,
    IAnalyticItem,
    IAnnotation,
    IAnnotationAgreement,
    IAnnotationLog,
    IAnnotationLogRequestPayload,
    IAnnotationTracking,
    IAnnotationTrackingResponse,
    IAssistedSliceSelection,
    IClassMappingSelection,
    IImageInpectionRes,
    IImageStatistics,
    IIntraRatingResponse,
    IMeasurementStats,
    IMeasurementStatsPayload,
    IPreAnnotation,
    getAnnotationTrackingData,
    transformDataForRequest,
} from '../entities';
import { IAnnotationQCStatus } from '../entities/IAnnotationQCStatus';
import { FilterValueResponse } from '../views/AnnotationDetails/views/Analytics/components/MeasurementStats/components/ChartFilter/entities';

export const AnnotationServiceSlice = APISlice.injectEndpoints({
    endpoints: builder => ({
        getAnnotationCS: builder.query<Array<IAnnotation>, IGetAnnotationArgs>({
            providesTags: ['ImageAnnotation'],
            query: ({ annotationId, imageId, userId, studyId, role }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotation`,
                params: { annotation_id: annotationId, image_id: imageId, user_id: userId, study_id: studyId, role },
            }),
        }),
        getAnnotationTrackingList: builder.query<Array<IAnnotationTracking>, string | undefined>({
            query: projectId => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotationList`,
                params: { projectId },
            }),
            transformResponse: (response: Array<IAnnotationTrackingResponse>) => response.map(getAnnotationTrackingData),
            providesTags: (result, error, projectId) =>
                projectId ? [{ type: 'AnnotationList', id: projectId }, 'AnnotationList'] : ['AnnotationList'],
        }),
        getQCStatus: builder.query<Array<IAnnotationQCStatus>, string | undefined>({
            query: projectId => ({
                url: `${ServiceEnum.Api}/Annotation/data-qc/GetDataQCList`,
                params: { annotation_id: projectId },
            }),
        }),

        getAnnotationListCS: builder.query<Array<IAnnotation>, IGetAnnotationArgs>({
            providesTags: ['ImageAnnotation'],
            query: ({ annotationId, imageId, userId, studyId, role }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotation`,
                params: { annotation_id: annotationId, image_id: imageId, user_id: userId, study_id: studyId, role },
            }),
        }),
        getImageBasedAnnotations: builder.query<Array<IAnnotation>, IGetAnnotationArgs>({
            providesTags: ['ImageAnnotation'],
            query: ({ annotationId, imageId }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetImageBasedAnnotations`,
                params: { annotation_id: annotationId, image_id: imageId },
            }),
        }),

        getAnalytics: builder.query<Array<IAnalyticItem>, IGetAnalyticsArgs>({
            query: ({ annotationId, skip, limit }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnalytics`,
                params: {
                    annotation_id: annotationId,
                    skip,
                    limit,
                },
            }),
        }),

        getReaderStats: builder.query<Array<IImageStatistics>, IGetReaderStats>({
            query: ({ annotation_id, user_id }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetReaderStats`,
                params: { annotation_id, user_id },
            }),
        }),

        saveAnnotationCS: builder.mutation<IAnnotation, ISaveAnnotationArgs>({
            query: ({ annotationList, annotationId, userId, imageId, studyId, role, colorMap }) => ({
                url: `${ServiceEnum.Api}/Annotation/SaveAnnotation`,
                method: 'POST',
                body: {
                    annotation_list: annotationList,
                    annotation_id: annotationId,
                    image_id: imageId,
                    study_id: studyId,
                    user_id: userId,
                    role,
                    colorMap,
                },
            }),
            invalidatesTags: ['ImageAnnotation', 'AnnotationCompletionStatus', 'AnnotationStudyAnalytics'],
        }),
        getInterRating: builder.query<any, IAgreementFields>({
            query: ({
                comparison_mode,
                user_id,
                problem_type,
                annotation_id,
                image_id,
                study_id,
                metric_name,
                image_inspection,
            }) => ({
                url: `${ServiceEnum.Validation}/annotation/agreement/filter/inter_rating`,
                method: 'POST',
                body: {
                    comparison_mode: comparison_mode,
                    user_id: user_id,
                    problem_type: problem_type,
                    annotation_id: annotation_id,
                    image_id: image_id,
                    study_id: study_id,
                    metric_name: metric_name,
                    image_inspection: image_inspection,
                },
            }),
        }),
        getVendorMetrics: builder.query<any, IAgreementFields>({
            query: ({
                comparison_mode,
                user_id,
                problem_type,
                annotation_id,
                image_id,
                study_id,
                metric_name,
                image_inspection,
            }) => ({
                url: `${ServiceEnum.Validation}/annotation/agreement/filter/vendor_metrics`,
                method: 'POST',
                body: {
                    comparison_mode: comparison_mode,
                    user_id: user_id,
                    problem_type: problem_type,
                    annotation_id: annotation_id,
                    image_id: image_id,
                    study_id: study_id,
                    metric_name: metric_name,
                    image_inspection: image_inspection,
                },
            }),
        }),
        getAgreementPlot: builder.query<any, IAgreementFields>({
            query: ({ comparison_mode, user_id, problem_type, annotation_id, image_id, study_id, metric_name, plot_type }) => ({
                url: `${ServiceEnum.Validation}/annotation/agreement/plot`,
                method: 'POST',
                body: {
                    comparison_mode: comparison_mode,
                    user_id: user_id,
                    problem_type: problem_type,
                    annotation_id: annotation_id,
                    image_id: image_id,
                    study_id: study_id,
                    metric_name: metric_name,
                    plot_type: plot_type,
                },
            }),
        }),
        getStudyAliasList: builder.query<Array<any>, any>({
            query: (annotation_id: string) => ({
                url: `${ServiceEnum.Api}/Annotation/GetStudyAliasList`,
                params: { annotation_id },
            }),
        }),
        getImageInspection: builder.mutation<Array<IImageInpectionRes>, IAgreementFields>({
            query: ({ comparison_mode, user_id, problem_type, annotation_id, options, image_id }) => ({
                url: `${ServiceEnum.Validation}/annotation/agreement/filter/image_inspection`,
                method: 'POST',
                body: {
                    annotation_id: annotation_id,
                    comparison_mode: comparison_mode,
                    problem_type: problem_type,
                    user_id: user_id,
                    options: options,
                    image_id: image_id,
                },
            }),
        }),
        getIntraRating: builder.mutation<Array<IIntraRatingResponse>, IAgreementFields>({
            query: ({ comparison_mode, user_id, problem_type, annotation_id, options, image_id }) => ({
                url: `${ServiceEnum.Validation}/annotation/agreement/filter/intra_rating`,
                method: 'POST',
                body: {
                    annotation_id: annotation_id,
                    comparison_mode: comparison_mode,
                    problem_type: problem_type,
                    user_id: user_id,
                    options: options,
                    image_id: image_id,
                },
            }),
        }),
        generateAgreement: builder.mutation<string, Partial<IAgreementFields>>({
            query: ({ irr_type, problem_type, annotation_id, dataset_id, options }) => ({
                url: `${ServiceEnum.Validation}/annotation/agreement/generate?irr_type=${irr_type}`,
                method: 'POST',
                body: {
                    annotation_id: annotation_id,
                    dataset_id: dataset_id,
                    problem_type: problem_type,
                    options: options,
                },
            }),
        }),

        getAgreementImageList: builder.query<Array<IAnnotationAgreement>, string>({
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/GetAgreementImageList?${params}`,
            }),
        }),

        getUserBasedImageList: builder.query<any, { annotation_id: string; user_id?: string }>({
            query: ({ annotation_id, user_id }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetUserBasedImageList`,
                params: {
                    annotation_id: annotation_id,
                    user_id: user_id,
                },
            }),
        }),
        getDicomMetadataCS: builder.query<Dictionary, { dataset_id: string; image_id: string }>({
            queryFn(params, api, extraOptions, baseQuery) {
                if (_isEmpty(params?.dataset_id) || _isEmpty(params?.image_id)) return { data: [] };

                return baseQuery({
                    url: `${ServiceEnum.Management}/image/get_image_meta`,
                    params,
                });
            },
        }),

        getAnnotationTrackingCS: builder.query<IAnnotationTracking, string>({
            query: annotation_id => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotationTracking`,
                params: { annotation_id },
            }),
            transformResponse: getAnnotationTrackingData,
            providesTags: (result, error, arg) => ['AnnotationTracking', { type: 'AnnotationList', id: arg }],
        }),

        getAnnotationTrackingListCS: builder.query<Array<IAnnotationTracking>, IGetAnnotationTrackingListArgs>({
            providesTags: ['AnnotationList'],
            query: ({ dataset_id, project_id, dataset_list }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotationTrackingList`,
                params: {
                    dataset_id: dataset_id ?? undefined,
                    project_id: project_id ?? undefined,
                    dataset_list: dataset_list ?? undefined,
                },
            }),
            transformResponse: (response: Array<IAnnotationTrackingResponse>) => response.map(getAnnotationTrackingData),
        }),

        getAnnotationProjectListCS: builder.query<Array<IAnnotationTracking>, any>({
            providesTags: ['AnnotationProjectList'],
            query: ({ dataset_id, status, project_status, project_id }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotationTrackingList`,
                params: {
                    dataset_id: dataset_id ?? undefined,
                    status: status ?? 'project',
                    project_status,
                    project_id: project_id ?? undefined,
                },
            }),
            transformResponse: (response: Array<IAnnotationTrackingResponse>) =>
                _compact(response.map(getAnnotationTrackingData)),
        }),

        applyPreAnnotationCS: builder.mutation<IAnnotation, IApplyPreAnnotationArgs>({
            query: ({ annotationId, validationId, configList, labelingConfig, preAnnotationMode, userList }) => ({
                url: `${ServiceEnum.Api}/Annotation/ApplyPreAnnotation`,
                method: 'POST',
                body: {
                    annotation_id: annotationId,
                    batch_job_id: validationId,
                    config_list: configList,
                    labeling_config: labelingConfig,
                    pre_annotation_mode: preAnnotationMode,
                    user_list: userList,
                },
            }),
            invalidatesTags: (result, error, arg) => ['AnnotationList', { type: 'AnnotationList', id: arg?.annotationId }],
        }),

        removePreAnnotationCS: builder.mutation<IAnnotation, any>({
            query: ({ annotationId, validationId }) => ({
                url: `${ServiceEnum.Api}/Annotation/RemovePreAnnotation`,
                method: 'POST',
                body: {
                    annotation_id: annotationId,
                    batch_job_id: validationId,
                },
            }),
        }),

        getPreAnnotation: builder.query<Array<IPreAnnotation>, IPreAnnotationArgs>({
            query: ({ annotationId, imageId, studyId }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetPreAnnotation`,
                params: { annotation_id: annotationId, image_id: imageId, study_id: studyId },
            }),
        }),

        getAssistedSliceSelectionCS: builder.query<IAssistedSliceSelection, IAssistedSliceSelectionArgs>({
            query: args => ({
                url: `${ServiceEnum.Api}/Annotation/GetAssistedSliceSelection`,
                params: args,
            }),
        }),

        getStudyListStatusCS: builder.query<
            Array<{ study_alias?: string; image_id?: string; status: string; reviewStatus: string }>,
            { annotation_id: string; reader_id?: string; is_study_dataset?: boolean }
        >({
            providesTags: ['StudyListStatus'],
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/GetStudyListStatus`,
                params,
            }),
        }),

        updateAnnotationTrackingCS: builder.mutation<IAnnotationTracking, Partial<IAnnotationTracking>>({
            query: args => ({
                url: `${ServiceEnum.Api}/Annotation/UpdateAnnotationTracking`,
                method: 'POST',
                body: _compactDict(transformDataForRequest(args)),
            }),
            invalidatesTags: (result, error, arg) => [
                'AnnotationList',
                'AnnotationTracking',
                'AnnotatorStatus',
                { type: 'AnnotationList', id: arg?.annotationId },
            ],
        }),

        getAssignmentStudies: builder.query<Array<IStudy>, IGetAssignedStudiesArgs>({
            providesTags: ['AssignmentStudies'],
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/GetAssignmentStudies`,
                params,
            }),
        }),

        getNextAssignmentStudy: builder.mutation<IStudy, IGetNextAssignmentStudyArgs>({
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/GetNextAssignmentStudy`,
                params,
            }),
        }),

        getAnnotationStudyAnalytics: builder.query<
            any,
            { study_id: string; annotation_id: string; user_id: string; image_id: string }
        >({
            providesTags: ['AnnotationStudyAnalytics'],
            queryFn(params, api, extraOptions, baseQuery) {
                if (_isEmpty(params?.annotation_id) || _isEmpty(params?.user_id)) return { data: [] };

                return baseQuery({
                    url: `${ServiceEnum.Api}/Annotation/GetAnnotationStudyAnalytics`,
                    params,
                });
            },
        }),

        getAnnotationStatusCS: builder.query<any, any>({
            providesTags: ['AnnotationCompletionStatus'],
            queryFn({ annotationId, imageId, userId, studyId }, api, extraOptions, baseQuery) {
                if (_isEmpty(annotationId) || _isEmpty(userId) || (_isEmpty(imageId) && _isEmpty(studyId))) return { data: [] };

                return baseQuery({
                    url: `${ServiceEnum.Api}/Annotation/GetAnnotationStatus`,
                    params: { annotation_id: annotationId, image_id: imageId, user_id: userId, study_id: studyId },
                });
            },
        }),

        updateAnnotationStatusCS: builder.mutation<IAnnotation, IUpdateAnnotationStatusArgs>({
            query: ({ annotationId, readerId, imageId, studyId, completionStatus, reviewStatus, rate, note }) => ({
                url: `${ServiceEnum.Api}/Annotation/UpdateAnnotationStatus`,
                method: 'POST',
                body: {
                    annotation_id: annotationId,
                    image_id: imageId,
                    study_id: studyId,
                    reader_id: readerId,
                    completion_status: completionStatus,
                    review_status: reviewStatus,
                    rate,
                    note,
                },
            }),
            invalidatesTags: ['AnnotationCompletionStatus', 'StudyListStatus', 'AssignmentStudies', 'AnnotationProjectStatus'],
        }),

        getReviewStatus: builder.query<any, any>({
            queryFn({ annotationId, readerId }, api, extraOptions, baseQuery) {
                if (_isEmpty(annotationId)) return { data: [] };
                return baseQuery({
                    url: `${ServiceEnum.Api}/Annotation/GetReviewStatus`,
                    params: { annotation_id: annotationId, reader_id: readerId },
                });
            },
        }),

        getTotalReviewStatus: builder.query<any, any>({
            queryFn({ annotationId }, api, extraOptions, baseQuery) {
                if (_isEmpty(annotationId)) return { data: [] };
                return baseQuery({
                    url: `${ServiceEnum.Api}/Annotation/GetTotalReviewStatus`,
                    params: { annotation_id: annotationId },
                });
            },
        }),

        getAnnotatorSummary: builder.query<any, IGetAnnotatorSummaryArgs>({
            queryFn({ annotationId }, api, extraOptions, baseQuery) {
                if (_isEmpty(annotationId)) return { data: [] };
                return baseQuery({
                    url: `${ServiceEnum.Api}/Annotation/GetAnnotatorSummary`,
                    params: { annotation_id: annotationId },
                });
            },
        }),

        completeProject: builder.mutation<any, ICompleteProjectArgs>({
            query: ({ annotationId, makeDefault }) => ({
                url: `${ServiceEnum.Api}/Annotation/CompleteProject`,
                method: 'POST',
                body: {
                    annotation_id: annotationId,
                    make_default: makeDefault,
                },
            }),
            invalidatesTags: ['AnnotationProjectList'],
        }),
        uncompleteProject: builder.mutation<any, ICompleteProjectArgs>({
            query: ({ annotationId }) => ({
                url: `${ServiceEnum.Api}/Annotation/UnCompleteProject`,
                method: 'POST',
                body: {
                    annotation_id: annotationId,
                },
            }),
            invalidatesTags: ['AnnotationProjectList'],
        }),

        getImageAnnotations: builder.query<Array<any>, string>({
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/GetImageAnnotations?${params}`,
            }),
        }),

        GetAnnotationAnalytics: builder.query<{ data: Array<IAnnotationLog>; total: number }, IAnnotationLogRequestPayload>({
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/analytics/`,
                params,
            }),
        }),

        GetMeasurementStats: builder.query<{ data: Array<IMeasurementStats>; total: number }, IMeasurementStatsPayload>({
            query: ({ label, ...params }) => ({
                url: `${ServiceEnum.Api}/Annotation/analytics/stats`,
                params: {
                    ...params,
                    label: _entries(label)
                        .filter(([, value]) => value)
                        .map(([key]) => key),
                },
            }),
        }),

        GetMeasurementStatParams: builder.query<FilterValueResponse, string>({
            query: annotation_id => ({
                url: `${ServiceEnum.Api}/Annotation/analytics/params`,
                params: { annotation_id },
            }),
        }),

        getProjectStatusQuery: builder.query<
            { imageCount: number; annotationCount: number },
            { annotation_id: string; reader_id: string }
        >({
            providesTags: ['AnnotationProjectStatus'],
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/GetProjectStatus`,
                params,
            }),
        }),

        getStudyBasedAnnotatorStatus: builder.query<any, IGetStudyBasedAnnotatorStatusArgs>({
            queryFn(params, api, extraOptions, baseQuery) {
                if (_isEmpty(params?.annotation_id)) return { data: [] };

                return baseQuery({
                    url: `${ServiceEnum.Api}/Annotation/GetStudyBasedAnnotatorStatus`,
                    params,
                });
            },

            providesTags: ['AnnotatorStatus'],
        }),

        getAnnotationCount: builder.query<{ count: number }, IGetAnnotationCountArgs>({
            query: ({ annotation_id, exportMyAnnotations, exportCompletedAnnotations, annotatorList }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAnnotationCount`,
                params: {
                    annotation_id: annotation_id ?? undefined,
                    exportMyAnnotations: exportMyAnnotations ?? undefined,
                    annotatorList: annotatorList ?? undefined,
                    exportCompletedAnnotations: exportCompletedAnnotations ?? undefined,
                },
            }),
        }),

        getAssignedImages: builder.query<IImage[], GetAssignedImagesArgs>({
            query: ({ annotation_id, reader_id, skip, limit }) => ({
                url: `${ServiceEnum.Api}/Annotation/GetAssignedImages`,
                params: { annotation_id, reader_id, skip, limit },
            }),
            transformResponse(baseQueryReturnValue: Array<IImageResponse>, meta, { dataset_id }) {
                return transformImages(baseQueryReturnValue, dataset_id);
            },
        }),
    }),
});

export const {
    useGetAnnotationListCSQuery,
    useGetImageBasedAnnotationsQuery,
    useGetAnalyticsQuery,
    useGetReaderStatsQuery,
    useSaveAnnotationCSMutation,
    useGetDicomMetadataCSQuery,
    useGetAnnotationTrackingCSQuery,
    useApplyPreAnnotationCSMutation,
    useRemovePreAnnotationCSMutation,
    useGetPreAnnotationQuery,
    useGetAssistedSliceSelectionCSQuery,
    useGetStudyListStatusCSQuery,
    useGetAnnotationTrackingListCSQuery,
    useGetQCStatusQuery,
    useUpdateAnnotationTrackingCSMutation,
    useGetAnnotationProjectListCSQuery,
    useGetAssignmentStudiesQuery,
    useGetAnnotationStudyAnalyticsQuery,
    useGetAnnotationStatusCSQuery,
    useUpdateAnnotationStatusCSMutation,
    useGetReviewStatusQuery,
    useGetTotalReviewStatusQuery,
    useGetAnnotatorSummaryQuery,
    useCompleteProjectMutation,
    useUncompleteProjectMutation,
    useGetInterRatingQuery,
    useGetVendorMetricsQuery,
    useGetAgreementPlotQuery,
    useGetStudyAliasListQuery,
    useGetImageInspectionMutation,
    useGetIntraRatingMutation,
    useGenerateAgreementMutation,
    useGetAgreementImageListQuery,
    useGetAnnotationTrackingListQuery,
    useGetUserBasedImageListQuery,
    useGetImageAnnotationsQuery,
    useGetAnnotationCSQuery,
    useGetAnnotationAnalyticsQuery,
    useGetNextAssignmentStudyMutation,
    useGetProjectStatusQueryQuery,
    useGetStudyBasedAnnotatorStatusQuery,
    useGetMeasurementStatsQuery,
    useGetMeasurementStatParamsQuery,
    useGetAnnotationCountQuery,
    useGetAssignedImagesQuery,
} = AnnotationServiceSlice;

interface IGetAnnotationArgs {
    annotationId: string;
    userId?: string;
    imageId?: string;
    studyId?: string;
    role?: string;
}

interface IUpdateAnnotationStatusArgs {
    annotationId: string;
    imageId?: string;
    studyId?: string;
    readerId?: string;
    reviewStatus?: string;
    completionStatus?: string;
    rate?: number;
    note?: string;
}
export interface ISaveAnnotationArgs extends IGetAnnotationArgs {
    annotationList: Array<IAnnotation>;
    role: string;
    colorMap: Dictionary<string>;
}

interface IApplyPreAnnotationArgs {
    annotationId: string;
    validationId: string;
    configList: Array<IClassMappingSelection>;
    labelingConfig: string;
    preAnnotationMode: string;
    userList?: Array<string>;
}

export interface IPreAnnotationArgs {
    annotationId: string;
    imageId: string;
    studyId: string;
}

export interface IAssistedSliceSelectionArgs {
    annotationId: string;
    studyId: string;
    form: string;
    measurement: string;
    objectName: string;
}

export interface IGetAssignedStudiesArgs {
    annotation_id: string;
    reader_id: string;
    skip?: number;
    limit?: number;
    slim?: boolean;
}

export interface IGetAnalyticsArgs {
    annotationId: string;
    skip?: number;
    limit?: number;
}

export interface IGetReaderStats {
    annotation_id: string;
    user_id: string;
}

export interface ICompleteProjectArgs {
    annotationId: string;
    makeDefault?: boolean;
}

export interface IGetNextAssignmentStudyArgs {
    annotation_id: string;
    reader_id: string;
    current_study_id: string;
}

export interface IGetAnnotatorSummaryArgs {
    annotationId: string;
}

interface IGetStudyBasedAnnotatorStatusArgs {
    annotation_id: string;
}

interface IGetAnnotationTrackingListArgs {
    dataset_id?: string | undefined;
    project_id?: string | undefined;
    dataset_list?: Array<string> | undefined;
}

interface IGetAnnotationCountArgs {
    annotation_id: string;
    exportMyAnnotations: string | boolean;
    exportCompletedAnnotations: string | boolean;
    annotatorList?: Array<string> | undefined;
}

interface GetAssignedImagesArgs {
    annotation_id: string;
    reader_id: string;
    skip?: number;
    limit?: number;
    dataset_id: string;
}
