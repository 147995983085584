import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataset, IDatasetFilters, IDataSource, SortingKey } from 'common';
import { DatasetStateFactory } from '../entities';
import { DatasetAnalysisReducer } from '../views/DatasetAnalysis/store';
import { datasetCurationReducer } from '../views/DatasetCuration/store';
import { AnnotationReducer } from '../views/DatasetDetails/views/Annotation/store';
import { MetadataReducer } from '../views/DatasetDetails/views/Metadata/store';
import { DatasetUploadReducer } from '../views/DatasetsUpload/store';
import { IPacsExplorerState } from '../views/PacsExplorer/entities';

export const DatasetSlice = createSlice({
    name: 'Dataset',
    initialState: DatasetStateFactory(),
    reducers: {
        setDatasetList(state, action: PayloadAction<Array<IDataset>>) {
            state.datasetList = action.payload;
        },
        updateDataset(state, { payload }: PayloadAction<IDataset>) {
            state.datasetList = state.datasetList.map(dataset => (dataset.datasetId === payload.datasetId ? payload : dataset));
        },
        updateArchiveDataset(state, { payload }: PayloadAction<IDataset>) {
            state.archivedDatasetList = state.archivedDatasetList.map(dataset =>
                dataset.datasetId === payload.datasetId ? payload : dataset
            );
        },
        setSourceList(state, { payload }: PayloadAction<Array<IDataSource>>) {
            state.sourceList = payload;
        },
        updateDatasetFiltering(state, { payload }: PayloadAction<Partial<IDatasetFilters>>) {
            Object.assign(state.common.datasetFilters, payload);
        },
        setArchivedDatasets(state, { payload }: PayloadAction<Array<IDataset>>) {
            state.archivedDatasetList = payload;
        },
        setDatasetEditDrawer(state, { payload }: PayloadAction<boolean>) {
            state.common.isDrawerVisible = payload;
        },
        setDatasetSortType(state, { payload }: PayloadAction<SortingKey>) {
            state.common.datasetSorting = payload;
        },
        setDatasetListLoading(state, { payload }: PayloadAction<boolean>) {
            state.common.datasetListLoading = payload;
        },
        setUpdateDatasetModal(state, { payload }: PayloadAction<boolean>) {
            state.common.isUpdateDatasetModalVisible = payload;
        },
        setClipboardModalVisible(state, { payload }: PayloadAction<boolean>) {
            state.common.isClipboardModalVisible = payload;
        },
        updatePacsExplorerState(state, { payload }: PayloadAction<Partial<IPacsExplorerState>>) {
            Object.assign(state.pacsExplorerState, payload);
        },

        ...DatasetUploadReducer,
        ...MetadataReducer,
        ...AnnotationReducer,
        ...datasetCurationReducer,
        ...DatasetAnalysisReducer,
    },
});

export const {
    setDatasetList,
    setSourceList,
    updateDatasetFiltering,
    updateDatasetUploadParams,
    setArchivedDatasets,
    updateDataset,
    setUploadLoading,
    updateMetadataUploadState,
    updateMetadataImageListState,
    updateMetadataListState,
    updateMetadataEditState,
    updateFileUploadState,
    updateAnnotationImageListState,
    updateAnnotationViewMode,
    updateSelectedAnnotationId,
    updateAnnotationPreviewModal,
    updateSelectedLabelingConfiguration,
    updateS3Params,
    setDatasetEditDrawer,
    setDatasetSortType,
    setUploadStatus,
    setUploadProgress,
    setSelectedDatasets,
    setThumbnailList,
    updateMetadataFilterFields,
    updateSelectedDatasets,
    updateCurationDatasetFiltering,
    setDatasetListLoading,
    updateDatasetCreationState,
    updateImageFilteringState,
    updateMetadataManagementState,
    updateArchiveDataset,
    updateDatasetAnalysisState,
    setUpdateDatasetModal,
    setClipboardModalVisible,
    updatePacsExplorerState,
} = DatasetSlice.actions;

export default DatasetSlice.reducer;
